<style scoped>
img {
  flex: 0 0 auto;
  align-self: center;
  max-width: 50%;
  max-height: 100px;
}
input[type="radio"] {
  max-width: 25px;
  flex: 0 0 auto;
}
b {
  text-align: left;
}
a.active {
  font-weight: bold;
}
.auto-form {
  flex: 0 1 max-content;
}
.sample-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 1 300px;
  position: relative;
  background: white;
  min-width: 300px;
  cursor: pointer;
}
@media (max-width: 900px) {
  .sample-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .auto-form {
    margin-bottom: 200px;
  }

  /* in a popover */
  .popover .style-editor {
    display: grid;
    height: 100%;
    max-height: calc(100vh - 150px);
    grid-template-rows: 1fr auto;
  }
  .popover .style-form {
    overflow: scroll;
    height: 100%;
  }
  .popover .sample-container {
    position: static;
  }
  .popover .sample-bg {
    position: static;
  }
}

.sample {
  max-width: 450px;
  overflow: hidden;
  text-align: left;
  display: grid;
}
.sample > * {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}
.sample p {
  position: relative;
  margin: 15px;
  z-index: 2;
}
.sample-bg {
  z-index: 1;
  background-size: cover;
  filter: blur(5px);
}
.picker-item {
  padding: 10px;
}
.picker-image {
  background: white;
  border: black;
  -webkit-mask-size: cover;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  height: 35px;
  max-width: 35px;
}
.chooser {
  border-radius: 5px;
}
.auto-form {
  flex: 1 1 600px;
  min-width: 50%;
}
</style>

<template>
  <div class="style-editor flex-row flex-wrap flex-align-top scroll responsive-max">
    <div class="style-form">
      <form @submit.prevent class="auto-form lined mellow flex-stretch">
        <div class="form-row">
          <label for="name">{{ $t("style.form-field-name") }}</label>
          <div>
            <input type="text" name="name" v-model="data.object.name" />
          </div>
        </div>
        <div class="form-row">
          <div />
          <label for="default" class="checkbox">
            <input
              name="default"
              type="checkbox"
              v-model="data.object.default"
              @change="makeOnlyOneDefault"
            />
            {{ $t("style.text-default-checkbox-option") }}
          </label>
        </div>
        <div class="form-row">
          <div />
          <label for="default" class="checkbox">
            <input
              name="default"
              type="checkbox"
              v-model="data.object.default_choice"
              @change="makeOnlyOneDefault"
            />
            {{ $t("style.choice-default-checkbox-option") }}
          </label>
        </div>
        <div class="form-row">
          <label for="foreground">Textbox Edge</label>
          <div class="flex-column">
            <div class="flex-row">
              <select name="border" v-model="data.object.border">
                <option value="">No Border</option>
                <option value="1px">Thin Border</option>
                <option value="3px">Thick Border</option>
                <option value="5px">Thiccc Border</option>
              </select>
              <color-picker
                v-model:value="data.object.borderColor"
                name="borderColor"
                default="rgba(0, 0, 0, 1)"
                :include-opacity="false"
              />
            </div>
            <div class="flex-row">
              <select name="borderShadow" v-model="data.object.borderShadow">
                <option value="">No Shadow</option>
                <option value="1px">Outline</option>
                <option value="3px">Light Shadow</option>
                <option value="5px">Glow Shadow</option>
                <option value="9px">Bright Shadow</option>
              </select>
              <color-picker
                v-model:value="data.object.borderShadowColor"
                name="borderShadowColor"
                default="rgba(255, 255, 255, 1)"
                :include-opacity="false"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <label for="foreground">{{ $t("style.foreground-shadow-form-label") }}</label>
          <div class="flex-row">
            <select v-model="data.object.shadowLength">
              <option value="">{{ $t("style.shadow-option-no-shadow") }}</option>
              <option value="1px">{{ $t("style.shadow-option-outline") }}</option>
              <option value="2px">{{ $t("style.shadow-option-normal") }}</option>
              <option value="7px">{{ $t("style.shadow-option-smeared") }}</option>
            </select>
            <!-- <div class="flex-row"> -->
            <color-picker
              name="foreground"
              v-model:value="data.object.foreground"
              default="rgba(0, 0, 0, 1)"
            />
            <color-picker
              name="shadow"
              v-if="data.object.shadowLength"
              v-model:value="data.object.shadow"
              default="rgba(255, 255, 255, 1)"
            />
            <!-- </div> -->
          </div>
        </div>
        <div class="form-row">
          <label for="background">{{ $t("style.form-field-background") }}</label>
          <div class="flex-row">
            <select v-model="data.object.gradient">
              <option value="">{{ $t("style.gradient-option-no-gradient") }}</option>
              <option value="left">{{ $t("style.gradient-option-left") }}</option>
              <option value="right">{{ $t("style.gradient-option-right") }}</option>
              <option value="bottom">{{ $t("style.gradient-option-downward") }}</option>
              <option value="top">{{ $t("style.gradient-option-upward") }}</option>
              <option value="bloom">{{ $t("style.gradient-option-bloom") }}</option>
            </select>
            <!-- <div class="flex-row"> -->
            <color-picker
              name="background"
              v-model:value="data.object.background"
              default="rgba(255, 255, 255, 0.4)"
              :include-opacity="false"
            />
            <color-picker
              name="background2"
              v-if="data.object.gradient != null"
              v-model:value="data.object.background2"
              default="rgba(255, 255, 255, 0.4)"
              :include-opacity="false"
            />
            <!-- </div> -->
          </div>
        </div>
        <div class="form-row">
          <label>{{ $t("alignment") }}</label>
          <div class="accented card flex-left">
            <select v-model="data.object.align">
              <option v-for="option of ['left', 'center', 'right']">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <label>{{ $t("style.font") }}</label>
          <div class="accented card flex-left">
            <list-chooser v-model:value="data.object.font" :list="FONTS">
              <template v-slot:empty>
                <div class="picker-item">{{ $t("style.default-font-label") }}</div>
              </template>
              <template v-slot="{ object: font }">
                <div class="picker-item" :style="{ fontFamily: font.font }">{{ font.name }}</div>
              </template>
            </list-chooser>
          </div>
        </div>
        <div class="form-row">
          <label>{{ $t("style.font-size") }}</label>
          <div class="accented card flex-left">
            <list-chooser v-model:value="data.object.size" :list="SIZES">
              <template v-slot:empty>
                <div class="picker-item">{{ $t("style.default-font-label") }}</div>
              </template>
              <template v-slot="{ object: size }">
                <div class="picker-item" :style="{ fontSize: size.size }">{{ size.name }}</div>
              </template>
            </list-chooser>
          </div>
        </div>
        <template v-if="data.object.particlesEnabled">
          <div class="form-row">
            <div>
              <label class="checkbox">
                {{ $t("style.typing-particles") }}
                <input
                  v-model="data.object.particlesEnabled"
                  @input="validate()"
                  class="static"
                  type="checkbox"
                />
              </label>
            </div>
            <div class="flex-row">
              <a
                v-for="t of ptabs"
                :key="t"
                @click="data.ptab = t"
                :class="{ active: t === data.ptab }"
                href="#"
              >
                {{ t }}
              </a>
            </div>
          </div>

          <div class="form-row" v-if="data.ptab === 'emission'">
            <label>Type</label>
            <div class="controls">
              <select v-model="data.object.particles.type" @input="validate()">
                <option>emitter</option>
                <option>particle</option>
              </select>
            </div>
          </div>
          <div class="form-row" v-if="data.ptab === 'emission'">
            <label> Emission </label>
            <div class="controls">
              <em>Count [{{ data.object.particles.count }}]</em>
              <input
                type="range"
                step="1"
                min="1"
                max="4"
                v-model="data.object.particles.count"
                @input="validate()"
                placeholder="count"
              />
              <em>Probability [{{ data.object.particles.probability }}]</em>
              <input
                type="range"
                step="0.05"
                min="0.05"
                max="1"
                v-model="data.object.particles.probability"
                @input="validate()"
                placeholder="probability"
              />
            </div>
          </div>
          <div class="form-row" v-if="data.ptab === 'emission'">
            <label>{{ $t("style.form-field-colors") }}</label>
            <div class="controls padded static flex-row">
              <color-picker
                name="color-1"
                v-model:value="data.object.particles.color1"
                :include-opacity="false"
              />
              <color-picker
                name="color-2"
                v-model:value="data.object.particles.color2"
                :include-opacity="false"
              />
            </div>
          </div>

          <div class="form-row" v-if="data.ptab === 'velocity'">
            <label>Rotation</label>
            <div class="controls">
              <em>Angle [{{ data.object.particles.a }}]</em>
              <input
                type="range"
                step="0.1"
                min="-3"
                max="3"
                v-model="data.object.particles.a"
                @input="validate()"
                placeholder="starting"
              />
              <em>Angle Randomization [{{ data.object.particles.da }}]</em>
              <input
                type="range"
                step="0.1"
                min="-3"
                max="3"
                v-model="data.object.particles.da"
                @input="validate()"
                placeholder="random"
              />
              <em>Rotation Randomization [{{ data.object.particles.da2 }}]</em>
              <input
                type="range"
                step="0.1"
                min="-3"
                max="3"
                v-model="data.object.particles.da2"
                @input="validate()"
                placeholder="velocity"
              />
            </div>
          </div>
          <div class="form-row" v-if="data.ptab === 'velocity'">
            <label>Speed</label>
            <div class="controls">
              <em>X Randomization [{{ data.object.particles.dx }}]</em>
              <input
                type="range"
                step="0.1"
                min="0"
                max="10"
                v-model="data.object.particles.dx"
                @input="validate()"
                placeholder="dx"
              />
              <em>Y Randomization [{{ data.object.particles.dy }}]</em>
              <input
                type="range"
                step="0.1"
                min="0"
                max="10"
                v-model="data.object.particles.dy"
                @input="validate()"
                placeholder="dy"
              />
              <em>Y Impulse [{{ data.object.particles.dy2 }}]</em>
              <input
                type="range"
                step="0.1"
                min="-10"
                max="10"
                v-model="data.object.particles.dy2"
                @input="validate()"
                placeholder="upward"
              />
            </div>
          </div>
          <div class="form-row" v-if="data.ptab === 'velocity'">
            <label>Gravity [{{ data.object.particles.gravity }}]</label>
            <input
              type="range"
              step="0.1"
              min="-10"
              max="10"
              v-model="data.object.particles.gravity"
              @input="validate()"
            />
          </div>
          <div class="form-row" v-if="data.ptab === 'shape'">
            <label>Size</label>
            <div class="controls">
              <em>Initial Size [{{ data.object.particles.size }}]</em>
              <input
                type="range"
                step="0.1"
                min="0"
                max="60"
                v-model="data.object.particles.size"
                @input="validate()"
                placeholder="in pixels"
              />
              <em>Size Randomization [{{ data.object.particles.dsize }}]</em>
              <input
                type="range"
                step="0.1"
                min="-2"
                max="2"
                v-model="data.object.particles.dsize"
                @input="validate()"
                placeholder="size delta"
              />
            </div>
          </div>
          <div class="form-row" v-if="data.ptab === 'shape'">
            <label>Duration</label>
            <input
              type="number"
              step="0.1"
              v-model="data.object.particles.duration"
              @input="validate()"
              placeholder="seconds"
            />
          </div>
          <div class="form-row flex-align-center" v-if="data.ptab === 'shape'">
            <list-chooser
              v-model:value="data.object.particles.decal"
              @update:value="validate()"
              :list="decals"
              title="Decal"
            >
              <template v-slot:empty>
                <div class="picker-item">Default</div>
              </template>
              <template v-slot="{ object: decal }">
                <div class="picker-item row" :style="{ color: 'white', background: 'black' }">
                  <div
                    class="picker-image"
                    :style="{
                      'mask-image': `url(${decal.id})`,
                      backgroundImage: `linear-gradient(${data.object.particles.color1}, ${data.object.particles.color2})`,
                    }"
                  ></div>
                  <span>{{ decal.name }}</span>
                </div>
              </template>
            </list-chooser>
          </div>
        </template>
        <div class="form-row" v-if="data.object.animation?.enter">
          <label>{{ $t("style.entrance-animation") }}</label>
          <div class="controls accented card">
            <animation-chooser
              class="static"
              :label="$t('style.none')"
              v-model:value="data.object.animation.enter"
              :story="book.story"
            />
          </div>
        </div>
        <!-- <div class="form-row">
          <label>{{ $t('style.exit-animation') }}</label>
          <div class="controls accented card">
            <animation-chooser
              class="static"
              :label="$t('style.none')"
              :value="data.object.animation.exit"
              :story="story"
              @update:value="update({exit: $event}, 'animation')"
            />
          </div>
        </div> -->
        <!-- <div class="form-row">
          <label>Typing Sounds</label>
          <div>
            --{{ data.object.sounds }}--
            <input-array
              @input="update({ sounds: $event })"
              :value="data.object.sounds || []"
            >
              <template v-slot="{value, index, input}">
                <div class="flex-column">
                  <media-uploader
                    accept="audio"
                    :value="value || {}"
                    @update:value="input($event)"
                  />
                  <div class="flex-row flex-left text-left flex-align-center">
                    <label for="id_offset">Offset</label>
                    <input
                      @input="input({ offset: $event.target.value })"
                      :value="value?.offset"
                      id="id_offset"
                      size="5"
                    >
                    <span>ms</span>
                  </div>
                </div>
              </template>
            </input-array>
          </div>
        </div> -->
        <!-- <div class="form-row">
          <label for="name">Inline Marker</label>
          <div>
            <input
              type="text" name="marker"
              pattern="[a-z]"
              v-model="data.object.marker"
              @input="doSave()"
            />
            <div class="help flex1">
              You can surround words in this marker to use this style in any text.<br>
              Example with marker ##:<br>
              "The trip was [fancy]eagerly anticipated[fancy]"
            </div>
          </div>
        </div> -->
      </form>
    </div>
    <div class="sample-container">
      <div class="sample">
        <div
          class="sample-bg"
          v-if="SAMPLE_TEXT[data.sampleIdx].background"
          :style="{ backgroundImage: `url(${SAMPLE_TEXT[data.sampleIdx].background})` }"
        />
        <p @click="handleSwitchText" :style="textStyle(data.object)">
          <!-- <typer-three
            :config="object"
            :text="samples[sampleIdx].text"
            :font="font ? font.url : null"
            :particle-config="data.object.particlesEnabled ? object.particles : null"
          /> -->
          <typer-tree
            ref="typer"
            :text="SAMPLE_TEXT[data.sampleIdx].text"
            :styleId="data.object.id"
            :particle-config="data.object.particlesEnabled ? data.object.particles : null"
            :animation-config="data.object.animation || animationConfig"
            :animations="book.story.animation"
            :variables="data.variables"
            :story="book.story"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Flare from "@/assets/particle-flare.png";
import Cloud from "@/assets/particle-smoke.png";
import TyperTree from "@/components/typer/typer_tree.vue";
import { FONTS, SAMPLE_TEXT, SIZES } from "@/global.js";
import { useStoryStore } from "@/stores/story";
import { ParticleConfig, Style } from "@/stores/story_defs";
import copy from "@/utils/copy";
import { textStyle } from "@/utils/tags";
import { nanoid } from "nanoid";
import { nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue";

const animationConfig = {
  enter: null,
  exit: null,
};

const props = defineProps<{
  id?: string;
}>();

const book = useStoryStore();

const data = reactive({
  sampleIdx: 0,
  samples: SAMPLE_TEXT,
  object: {} as Style,
  ptab: "emission",
  variables: { health: 0.012, status: "walking" },
  updateVariablesTimeout: 0,
});
const typer = ref();

const ptabs = ["emission", "velocity", "shape"];
const decals = [
  { id: Flare, name: "flare" },
  { id: Cloud, name: "cloud" },
];
const DEFAULT_STYLE_CONFIG: Partial<Style> = {
  border: "",
  shadow: "",
  foreground: "black",
  background: "white",
  gradient: "",
  align: "left",
  font: FONTS.find((f) => f.default).font,
  size: SIZES.find((s) => s.default).size,
  borderShadow: "",
  shadowLength: "",
};
const DEFAULT_PARTICLE_CONFIG: ParticleConfig = {
  type: "particle",
  probability: 1.0,
  color1: "#ff8000",
  color2: "#c0c0c0",
  count: 1,
  a: 0,
  da: 0.2,
  da2: 0.1,
  dx: 0.5,
  dy: 0.5,
  dy2: 0.8,
  gravity: 4.4,
  size: 20,
  dsize: 0.7,
  duration: 0.9,
  decal: Flare,
};

watch(
  () => data.object,
  () => {
    if (data.object.name && !data.object.id) {
      data.object.id = nanoid();
      book.styles.push(data.object);
    }
  },
  { deep: true },
);

watch(
  () => data.object.particlesEnabled,
  () => {
    for (const prop in DEFAULT_PARTICLE_CONFIG) {
      if (!data.object.particles[prop] && data.object.particles[prop] !== 0) {
        data.object.particles[prop] = DEFAULT_PARTICLE_CONFIG[prop];
      }
    }
  },
);

onMounted(() => {
  if (props.id) {
    data.object = book.styles.find((s) => s.id === props.id);
  }

  if (!data.object) {
    data.object = {
      id: "",
      name: "",
      ...DEFAULT_STYLE_CONFIG,
    };
  } else {
    // fill in defaults
    for (const key in DEFAULT_STYLE_CONFIG) {
      if (!data.object[key]) {
        data.object[key] = DEFAULT_STYLE_CONFIG[key];
      }
    }
    if (!data.object.animation) {
      data.object.animation = copy(animationConfig);
    }

    // fill in default particle configuration
    if (!data.object.particles) {
      data.object.particles = copy(DEFAULT_PARTICLE_CONFIG);
      data.object.particlesEnabled = false;
    }
  }

  data.updateVariablesTimeout = window.setInterval(() => {
    if (data.variables.health > 50) {
      data.variables.health -= Math.floor(Math.random() * 40);
    }
    data.variables.health += Math.floor(Math.random() * 30);
    const statuses = ["walking", "running", "sitting", "standing", "resting"];
    data.variables.status = statuses[Math.floor(Math.random() * statuses.length)];
  }, 2000);
});

onUnmounted(() => {
  if (data.object.id && !data.object.name) {
    data.object.name = "untitled " + nanoid();
  }
  clearInterval(data.updateVariablesTimeout);
});

async function validate() {
  await nextTick();
  data.object.particlesEnabled = data.object.particlesEnabled;
  const tests = [
    { name: "probability", min: 0.05, max: 1.0 },
    { name: "a", min: -3, max: 3 },
    { name: "da", min: -3, max: 3 },
    { name: "da2", min: -3, max: 3 },
    { name: "dx", min: 0, max: 10 },
    { name: "dy", min: 0, max: 10 },
    { name: "dy2", min: -10, max: 10 },
    { name: "duration", min: 0, max: 1.5 },
    { name: "size", min: 0, max: 60 },
    { name: "dsize", min: -2, max: 2 },
    { name: "count", min: 1, max: 4 },
    { name: "gravity", min: -10, max: 10 },
  ];
  for (const test of tests) {
    const value = parseFloat(data.object.particles[test.name]);
    if (value || value === 0) {
      data.object.particles[test.name] = Math.min(Math.max(value, test.min, test.max));
    }
  }
}

function makeOnlyOneDefault() {
  if (data.object.default) {
    book.styles.filter((s) => s.id != data.object.id).forEach((s) => (s.default = false));
  }
  if (data.object.default_choice) {
    book.styles.filter((s) => s.id != data.object.id).forEach((s) => (s.default_choice = false));
  }
}

async function handleSwitchText() {
  await typer.value.clear();
  data.sampleIdx = (data.sampleIdx + 1) % data.samples.length;
}
</script>
