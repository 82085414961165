import GetTutorials from "@/assets/data/tutorials";
import { fetch, put } from "@/utils/storage";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

// TODO: hook up tutorial popups

export interface Tutorial {
  message: string;
  point: { x: number; y: number };
  size?: number;
}

export const useTutorialStore = defineStore("tutorials", () => {
  const disableAll = ref(fetch("disable-tuts", false) as boolean);
  const completed = ref(fetch("tuts", {}) as Record<string, boolean>);
  const tutorials = ref([] as Tutorial[]);
  const stories = ref(GetTutorials());

  const current = computed(() => {
    if (tutorials.value.length > 0) {
      return tutorials.value[0];
    }
    return null;
  });

  watch(completed, () => put("tuts", completed.value), { deep: true });

  async function check(msg: string, el: HTMLElement, always = false) {
    if (el && !disableAll.value && (always || !completed.value[msg])) {
      // wait for animation to finish
      await new Promise((r) => setTimeout(r, 300));

      if (el.$el) {
        el = el.$el;
      }

      const box = el.getBoundingClientRect();
      if (box.top < window.innerHeight) {
        tutorials.value.push({
          message: msg,
          point: {
            x: box.left + box.width / 2,
            y: box.top + box.height / 2,
          },
        });
        completed.value[msg] = true;
        el.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }

  function next() {
    if (tutorials.value.length) {
      tutorials.value.shift();
    }
  }

  return {
    current,
    completed,
    stories,
    check,
    next,
  };
});
