<template>
  <input v-model="value" :invalid="!!errors" v-bind="$attrs" />
</template>

<script setup lang="ts">
import { useField } from "vee-validate";

const props = defineProps<{ name: string }>();

const { value, errors } = useField<number>(props.name);
</script>
