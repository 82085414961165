<template>
  <AdminList
    v-model="book.music"
    @update:action="emits('update:action', $event)"
    @update:selected="emits('update:selected', $event)"
    @pick="emits('update:modelValue', $event)"
    :action="props.action"
    :selected="props.selected || props.modelValue"
    :config="schema"
  />
</template>

<script setup lang="ts">
import Media from "@/models/media";
import { useStoryStore } from "@/stores/story";
import { AudioSource } from "@/stores/story_defs";
import AdminList, { AdminConfig } from "@morphosis/base/components/admin/AdminList.vue";
import { VvField } from "@morphosis/base/components/registry";

const book = useStoryStore();
const props = defineProps<{ modelValue?: string; action?: string; selected?: string }>();
const emits = defineEmits(["update:modelValue", "update:action", "update:selected"]);

const schema: AdminConfig = {
  searchFields: ["name"],
  listDisplay: [
    { label: "Audio", source: "blob_id|audio" },
    { label: "ID", source: "blob_id" },
    { label: "Name", source: "media_name" },
  ],
  form: [
    {
      type: "media",
      name: "blob_id",
      label: "File",
      validation: "required",
      attrs: {
        accept: "audio",
      },
    },
  ] as VvField[],
  fields: {
    async media_name(record: AudioSource) {
      const media = await Media.load(record.blob_id);
      return (media?.name ?? "").trim() || "Untitled";
    },
  },
};
</script>

<style scoped></style>
