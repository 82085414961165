<style>
@import url(font-awesome/css/font-awesome.css);
@import url(@morphosis/base/styles/base.scss);
@import url(@morphosis/base/styles/flex.css);
@import url(./components/scene_read.css);

.big {
  font-size: 2em;
}
#warn {
  padding: 10px;
}
#warn a {
  display: inline;
}
::-webkit-scrollbar {
  display: none;
}

/* debug styles */
#app.lang-en-lol {
  text-transform: uppercase;
  font-family: architects_daughterregular;
  font-size: 13px;
}
/* for landscape keyboard usage */
@media (max-height: 500px) {
  #app.keyboard-shown .keyboard-crunch {
    display: none;
  }
  #app.keyboard-shown .keyboard-crunch:focus-within {
    /* display: none; */
    display: unset;
  }
  #app.keyboard-shown .sidebar-detail .detail {
    max-height: 100vh;
    max-height: -webkit-fill-available;
  }
}
.locked {
  filter: blur(5px);
}
</style>

<template>
  <div
    v-if="isReady"
    :class="{
      debug: debug,
      [`lang-${$i18n.locale}`]: true,
      'keyboard-shown': keyboardShown,
    }"
  >
    <div id="inner" :class="{ locked: lockscreenVisible }">
      <tutorial-message />
      <div class="page-cont">
        <router-view class="page" v-slot="{ Component }">
          <keep-alive include="browser">
            <transition :name="transition">
              <component :is="Component" />
            </transition>
          </keep-alive>
        </router-view>
      </div>
      <alert-list />
      <automated-cursor ref="cursor" v-if="automated" />
      <tutorial-overview ref="tutorial" v-model:story-id="tutorialId" />
    </div>
    <lock-screen v-model:visible="lockscreenVisible" :code="securityPasscode" />

    <!-- april fools event -->
    <malpractice-count />
  </div>
  <div v-else>
    <div class="flex-column flex-center flex-align-center">
      <div class="flex-column flex-static card mellow padded">
        <div>{{ migrate.status ? migrate.status : $t("app.loading") }}</div>
        <div v-if="migrate.progress !== null">
          {{ Math.floor(migrate.progress * 10000) / 100 }}%<br />
          {{ isReady }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { App } from "@capacitor/app";
import { Clipboard } from "@capacitor/clipboard";
import { Capacitor } from "@capacitor/core";
import { Filesystem } from "@capacitor/filesystem";
import AlertList from "@morphosis/base/components/AlertList.vue";
import PushMixin from "@morphosis/base/mixins/push_mixin";
import SyncMixin from "@morphosis/base/mixins/sync_mixin";
import UserMixin from "@morphosis/base/mixins/user_mixin";
import { useThemeStore } from "@morphosis/base/stores/theme";
import { useToasterStore } from "@morphosis/base/stores/toaster";
import { useWebsocketStore } from "@morphosis/base/stores/websocket";
import { Deferred } from "@morphosis/base/utils/deferred";
import GameMixin from "@morphosis/tsunami-node/lib/game_mixin";
import debug from "debug";
import localForage from "localforage";
import LogRocket from "logrocket";
import { mapStores } from "pinia";
import shortid from "shortid";
import sortby from "sort-by";
import emitter from "tiny-emitter/instance";
import AutomatedCursor from "./components/automation/AutomatedCursor.vue";
import MalpracticeCount from "./components/seasonal/malpractice_cost.vue";
import TutorialOverview from "./components/tutorial_overview.vue";
import { loadLanguageAsync } from "./i18n";
import IAPMixin from "./mixins/iap";
import Media from "./models/media";
import LockScreen from "./views/lockscreen.vue";
import TutorialMessage from "./views/tutorial_message.vue";

const log = debug("app");

localForage.config({
  // driver      : localforage.WEBSQL, // Force WebSQL; same as using setDriver()
  name: "cyoa",
  version: 1.0,
  // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: "cyoa", // Should be alphanumeric, with underscores.
});

const loadedLanguages = ["en"];

const mixins = [GameMixin, IAPMixin, SyncMixin, UserMixin];
if (!window.electron) {
  mixins.push(PushMixin);
}

export default {
  name: "app",
  mixins,
  components: {
    AlertList,
    AutomatedCursor,
    LockScreen,
    MalpracticeCount,
    TutorialMessage,
    TutorialOverview,
  },
  head() {
    return {
      titleTemplate: "%s | CYOA Factory",
      meta: [
        { name: "og:title", content: "CYOA Factory" },
        {
          name: "og:image",
          content: "https://tsunami.darkchocolate.dev/static/cyoa/assets/icon.png",
        },
        { name: "og:description", content: "Write your own-choice based stories!" },
        { name: "og:url", content: `https://tsunami.darkchocolate.dev/static/cyoa/` },
      ],
    };
  },
  data() {
    let reviewUrl = "https://morphosisgames.itch.io/cyoa-factory";
    let platform = Capacitor.getPlatform();
    if (platform === "ios") {
      reviewUrl = "itms-apps://itunes.apple.com/app/1358855111";
    } else if (platform === "android") {
      reviewUrl = "https://play.google.com/store/apps/details?id=com.morphosisgames.cyocyoa";
    }

    const version = "???";
    const inAppEvent = this.fetch("inAppEvent", "none");
    const now = new Date();
    return {
      version,
      maxStoryVersion: 4,
      platform,
      PRODUCTION: import.meta.env.NODE_ENV === "production",
      ready: false, // will be a promise
      isReady: false,
      dark: this.fetch("dark-mode", true),
      debug: this.fetch("debug-mode", false),
      accent: this.fetch("accent-color", "#B3B3B3"),
      spark: this.fetch("spark-color", "#E27300"),
      favorites: this.fetch("favorites", {}),
      allowLogging: this.fetch("allowLogging", false),
      allowSyncing: this.fetch("allowSyncing", true),
      overlayout: this.fetch("overlayout", false),
      inAppEvent,
      autoInAppEvent: this.fetch("autoInAppEvent", true),
      eventDetails: this.fetch("appEventDetails", null),
      showNotificationTab: this.fetch("show-notification-tab", false),
      connectionPreference: true, //this.fetch('stayOnline', false),
      fsRoot: this.fetch("fsRoot", false),
      blockedUsers: this.fetch("blockedUsers", []),
      securityPasscode: this.fetch("securityPasscode", null),
      allowOnlineContent: this.fetch("allowOnlineContent", true),
      lockscreenVisible: false,
      flags: {
        capacitor: Capacitor.getPlatform() !== "web",
        electron: !!window.electron,
        ios: platform === "ios",
        chromeShare: !!navigator.share,
        iap: !!window.store,
      },
      editing: this.fetch("editMode", true),
      keyboardShown: false,
      story: {
        scenes: [],
      },
      transition: "slide-forward",
      scene: {},
      oldUser: this.fetch("user", {
        user_id: null,
        token: null,
        username: null,
        email: null,
        name: null,
        bio: null,
      }),
      reviewUrl,
      automated: this.fetch("automated", false),
      popupClass: null,
      popupArgs: null,
      popupShow: false,
      popupVisible: new Deferred(),
      popupValue: null,
      tutorialId: null,
      notifications: [],
      now,
      migrate: { status: "", progress: null },
      viewStatus: null,
      profile: null,
      server: import.meta.env.VITE_APP_TSUNAMI_SERVER.replace("/api/", "/"),
    };
  },
  computed: {
    isAdmin() {
      if (this.user) {
        const hats = this.user.hats || [];
        return hats.includes("admin") || hats.includes("super");
      }
      return false;
    },
    ...mapStores(useWebsocketStore, useToasterStore, useThemeStore),
  },
  watch: {
    eventDetails() {
      this.put("appEventDetails", this.eventDetails);
    },
    favorites: {
      handler() {
        this.put("favorites", this.favorites);
      },
      deep: true,
    },
    "$route.path": function (n, old) {
      const count = (path) => path.split(/\//g).length;
      this.transition = count(n) >= count(old) ? "slide-forward" : "slide-back";
    },
    editing() {
      this.put("editMode", this.editing);
    },
    allowLogging() {
      this.setupLogging(true);
    },
    "$i18n.locale"() {
      this.put("locale", this.$i18n.locale);
    },
    popupShow() {
      if (!this.popupShow) {
        this.popupVisible.resolve(false);
      }
    },
    $route: {
      handler() {
        this.popupShow = false;
      },
      deep: true,
    },
  },
  async created() {
    this.ready = new Promise((r) => (this._resolveReady = r));
    this.isReady = false;
    this.MODELS.story.ManagerClass.prefix = "cyoa";
    Media.ManagerClass.prefix = "cyoa";
    this.$i18n.locale = this.fetch("locale", "en") || "en";
    loadLanguageAsync();

    clearTimeout(window.errorTimeout);
    await this.websocketStore.setup();
    await this.themeStore.setup();
  },
  async mounted() {
    window.app = this;

    this.loggedIn = this.usersStore.ready;

    this.migrate.status = "Loading app...";

    // get app version
    if (Capacitor.getPlatform() !== "web") {
      this.appInfo = await App.getInfo();
      this.version = this.appInfo.version;
    }

    this.migrate.status = "Setting up events...";

    // start listeners right away
    window.addEventListener("onorientationchange", this.calcVH.bind(this), true);
    window.addEventListener("resize", this.calcVH.bind(this), true);
    window.addEventListener("keypress", this.handleCapture);
    window.addEventListener("keydown", this.handleCapture);
    document.addEventListener("backbutton", this.androidBack, false);
    document.addEventListener("hidekeyboard", () => {
      this.keyboardShown = false;
    });
    document.addEventListener("showkeyboard", () => {
      this.keyboardShown = true;
    });
    window.addEventListener("keyboardDidShow", () => {
      this.keyboardShown = true;
    });
    window.addEventListener("keyboardDidHide", () => {
      this.keyboardShown = false;
    });

    this.calcVH();

    this.migrate.status = "Setting up files...";

    // pause to allow user mixin to finish
    await this.$nextTick();
    await this.setupFilesystem();

    this.migrate.status = "Logging...";

    await this.setupLogging();

    this.migrate.status = "Done!";

    // this stuff can work in the background
    this.isReady = true;
    this.disconnected = !this.websocketStore.socket.connected;
    this.syncModels = Object.values(this.MODELS);
    await this.syncAllModels();
    try {
      await this.http("user_stat", { key: "open" });
    } catch (error) {
      console.warn("failed to send user state");
    }
  },
  methods: {
    onInternal(...args) {
      emitter.on(...args);
    },
    offInternal: emitter.off,
    onceInternal: emitter.once,
    emitInternal(...args) {
      emitter.emit(...args);
    },
    addAlert(msg) {
      this.toasterStore.add(msg);
    },
    async resetAutomation(story) {
      this.put("story_test", null);

      const old = await this.MODELS.story.load("test");
      if (old) {
        await old.delete();
      }

      const data = this.copy((await import("./automation_story.json")).default);
      await this.createStoryDocuments(data);
      await this.$nextTick();
      await new Promise((r) => setTimeout(r, 500));

      this.$router.push("/" + Math.random());
    },
    async patchStoryAutomated(patch) {
      const old = await this.MODELS.story.load("test");
      if (old) {
        await old.delete();
      }
      await new Promise((r) => setTimeout(r, 100));

      const data = this.copy((await import("./automation_story.json")).default);
      await this.createStoryDocuments(data);
      await this.$nextTick();
      const story = this.copy(await this.loadFullStory("test"));
      for (const prop in patch) {
        story[prop] = patch[prop];
      }
      await this.createStoryDocuments(story);
      const story2 = this.copy(await this.loadFullStory("test"));
      await this.$nextTick();
      await new Promise((r) => setTimeout(r, 500));
    },
    setupLogging(reload = false) {
      // logrocket
      // but not on iOS
      if (this.platform !== "ios") {
        if (this.allowLogging) {
          log(
            "BLAST OFF!",
            localStorage.allowLogging,
            this.fetch("allowLogging", true),
            this.allowLogging,
          );
          LogRocket.init("dark-chocolate/cyoa");
          LogRocket.identify(this.user.id);
        } else if (reload) {
          setTimeout(() => location.reload(), 100);
        }
      }
    },
    async getFilesystem(name, ext = "json") {
      let driver = null;
      const { publicStorage } = await Filesystem.checkPermissions();

      if (this.flags.electron) {
        driver = (await import("@morphosis/base/utils/file/JetpackDriver")).default;
      } else if (publicStorage === "granted" && this.flags.capacitor) {
        driver = (await import("./utils/file/MultiDriver")).default;
      } else {
        driver = (await import("./utils/file/LocalForageDriver")).default;
      }

      if (driver.setDrivers) {
        const driver1 = (await import("./utils/file/LocalForageDriver")).default;
        const driver2 = (await import("@morphosis/base/utils/file/CapacitorDriver")).default;
        driver.setDrivers(driver1, driver2);
      }
      return new driver(name, ext);
    },
    async setupFilesystem() {
      if (!this._setupFilesystem) {
        this._setupFilesystem = true;
        let driver = null;

        if (this.flags.electron) {
          try {
            this.migrate.status = "Using jetpack...";
            driver = (await import("@morphosis/base/utils/file/JetpackDriver")).default;
          } catch (e) {
            this.toasterStore.add({
              title: "Error",
              message: `Jetpack failed: ${e.message}`,
            });
            this.migrate.status = "Using idb...";
            driver = (await import("./utils/file/LocalForageDriver")).default;
          }
        } else {
          this.migrate.status = "Loading filesystem driver...";
          let { publicStorage } = await Filesystem.checkPermissions();
          if (publicStorage === "granted" && this.flags.capacitor) {
            driver = (await import("./utils/file/MultiDriver")).default;
          } else {
            driver = (await import("./utils/file/LocalForageDriver")).default;
          }
        }

        if (driver.setDrivers) {
          const driver1 = (await import("./utils/file/LocalForageDriver")).default;
          const driver2 = (await import("@morphosis/base/utils/file/CapacitorDriver")).default;
          driver.setDrivers(driver1, driver2);
          app.driver = new driver2();
        }
        this._$tmp = new driver("tmp");
        this._$stories = new driver("stories", "json");
        this._$reading = new driver("reading", "json");
        this._$images = new driver("images");
        this._$images2 = new driver("imagesbackup");

        this.migrate.status = "Waiting for stories...";
        await this._$stories.ready();
        this.migrate.status = "Waiting for reading...";
        await this._$reading.ready();
        this.migrate.status = "Waiting for images...";
        await this._$images.ready();
        this.migrate.status = "Waiting for images2...";
        await this._$images2.ready();

        try {
          this.migrate.status = "Waiting for media...";
          await Media.ready();
        } catch (e) {
          this.migrate.status = `Error: ${e}`;
          await new Promise((r) => setTimeout(r, 15 * 1000));
        }

        // try {
        //   await Promise.all([
        //     this._$stories.ready(),
        //     this._$reading.ready(),
        //     this._$images.ready(),
        //     this._$images2.ready(),
        //     Media.ready(),
        //   ]);
        // } catch (e) {
        //   this.migrate.status = `Error: ${e}`;
        // }

        // do system migrations
        try {
          this.migrate.status = "Waiting for migrations...";
          if (this._$stories.isMergable) {
            const second = this._$stories.secondary.length;
            if (this._$stories.primary.length != second && second > 0) {
              const backups = [];
              await this._$stories.secondary.iterate((s) => {
                backups.push(s);
              });

              await this.mergeStories(backups);
            }
          }

          this.migrate.status = "Waiting for login...";
          await this.loggedIn;
          this.migrate.status = "Waiting for system migration...";
          await this.migrateSystem();

          // version 4: use jetpack for electron & filesystem for capacitor
          console.info("using fs:", this.$stories.type);
          if (
            this.$stories.type != "localforage" &&
            (await this.$stories.length()) === 0 &&
            (await this.$reading.length()) === 0
          ) {
            log("migrating to new db");
            let retval = [];
            await this.setupOldFilesystem();
            for (const key of ["stories", "reading", "images"]) {
              await this[`__${key}`].iterate((old, name) => {
                retval.push(this[`$${key}`].setItem(name, old));
                log("making copy", key, name, old);
              });
            }
            await Promise.all(retval);
            log("migration complete");
          }

          // version 3
          this.migrate.status = "Waiting for story count...";
          let count = await this.$stories.length();

          // version 2
          if (count === 0) {
            for (const story of await this.read("stories", [])) {
              await this.$stories.setItem(story.id, story);
              count += 1;
            }
          }

          // version 1
          if (count === 0) {
            for (const story of this.fetch("stories") || []) {
              await this.$stories.setItem(story.id, story);
              count += 1;
            }
          }
        } catch (ex) {
          this.toasterStore.add({
            message: ex.message,
            title: this.$t("App.error"),
          });
        }
        this._resolveReady();
      } else {
        console.warn("not doing filesystem");
      }
    },
    async setupOldFilesystem() {
      let driver = await import("./utils/file/LocalForageDriver");
      this.__stories = new driver.default("stories");
      this.__reading = new driver.default("reading");
      this.__images = new driver.default("images");
    },
    async toggleFileFavorite(file) {
      const id = file.fid;
      if (id) {
        this.favorites[id] = !this.favorites[id];
        await this.http("public_stat", {
          id,
          name: "favorites",
          action: this.favorites[id] ? "add" : "remove",
        });
      } else {
        console.error("can't favorite", id);
      }
    },
    calcVH() {
      // var vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      // this.$el.setAttribute("style", "height:" + vH + "px;");
    },
    id() {
      return shortid.generate();
    },
    debounceSave(story) {
      clearTimeout(this.__save);
      this.__save = setTimeout(() => {
        this.__save = null;
        this.save(story || this.story);
      }, 1000);
    },
    async clipboardCopy(text) {
      if (this.flags.capacitor) {
        await Clipboard.write({ string: text });
      } else {
        navigator.clipboard.writeText(text);
      }
    },
    async clipboardPaste() {
      if (this.flags.capacitor) {
        const { type, value } = await Clipboard.read();
        return value;
      } else {
        return navigator.clipboard.readText();
      }
    },
    handleCapture(e) {
      const el = this.$refs.page;
      if (el && el.handleKeydown) {
        el.handleKeydown(e);
      }
    },
    handleAndroidBack() {
      this.handledBack = true;
    },
    async androidBack(e = null) {
      if (e) {
        e.preventDefault();
      }
      this.handledBack = false;
      await this.$nextTick();
      this.$emit("androidback");
      if (!this.handledBack) {
        this.$router.back();
      }

      // if (this.status === 'play') {
      //   this.status = null;
      // } else if (confirm('Are you sure you want to exit ?')) {
      //   navigator.app.exitApp();
      // }
    },
    async handleGameEvent(event, data) {
      this.$emit(`game-event`, event, data);
    },
    addBlockedUser(uid) {
      if (uid !== this.$root.uid && this.blockedUsers.indexOf(uid) === -1) {
        this.blockedUsers.push(uid);
        this.put("blockedUsers", this.blockedUsers);
      }
    },
    async refreshNotifications() {
      const response = await this.http("notification_list");
      this.notifications = response.list;
      this.notifications.sort(sortby("timestamp"));
    },
    async migrateSystem() {
      // move $images to Media
      // TODO: restore this after testing
      // this.migrate.status = "Migrating images...";
      // this.migrate.progress = 0;
      // let idx = 0;
      // let length = 0;
      // const promises = [];
      // await this.$images.iterate((image, key) => {
      //   console.log('migrating image', key, idx, image);
      //   length += 1;
      //   promises.push((async () => {
      //     if (image.type === 'text/plain' || image.type === 'application/octet-stream') {
      //       image = new Blob([image], { type: 'image/png' });
      //     }
      //     const media = await Media.importBlob(image, key);
      //     if (media.dispose) {
      //       await media.dispose();
      //     }
      //     // await this.$images.removeItem(image.blob_id);
      //     idx += 1;
      //     this.migrate.progress = idx / length;
      //   })());
      // });
      // await Promise.all(promises);
    },
  },
};
</script>
