<template>
  <Textarea v-model="value" :invalid="!!errors" auto-resize />
</template>

<script setup lang="ts">
import Textarea from "primevue/textarea";
import { useField } from "vee-validate";

const props = defineProps<{ name: string }>();

const { value, errors } = useField<string>(props.name);
</script>
