<style scoped>
.status-indicator {
  font-size: 13px;
  font-style: italic;
  color: var(--spark_text);
  background: var(--spark);
  border-radius: 5px;
  padding: 4px;
  flex: 0 0 auto;
}
.scene-header a {
  padding: 15px 9px;
  margin: 0;
}
.scene {
  max-width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
}
/* .title {
    padding: 10px;
    flex: 0 0 auto;
    text-align: right;
  } */
.text {
  width: 100%;
  max-width: 100vw;
  font-size: 1.5em;
  padding: 5px;
  flex: 1 1 auto;
  white-space: pre-wrap;
  text-align: left;
  word-wrap: break-word;
}
.choices {
  flex: 0 0 auto;
}
form {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.scene-title {
  background: transparent;
  border: none;
  margin: 10px;
  font-size: 14px;
  text-align: left;
}
.sidebar li {
  text-align: left;
}
.user-choice-icon {
  display: inline-block;
  margin-left: 15px;
}
.direction.helper,
.direction.helper select {
  /* background: #e8e8e8; */
}
.handle {
  color: grey;
  font-size: 15px;
  padding: 0 5px 5px 0;
}
.info {
  text-align: left;
  background: lightblue;
  color: #777;
}
.direction-list {
  flex: 0 0 auto;
  scroll-margin: 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}
.directions {
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  max-width: 900px;
  margin: 0;
}
.large-draggable-list {
  flex: 1 1 auto;
}
.references {
  display: none;
}
@media (min-width: 800px) {
  .references {
    flex: 2 2 1px;
    position: relative;
    display: flex;
    min-width: 0;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.reference {
  width: 100%;
  max-width: 300px;
  background: var(--spark);
  border-radius: 12px;
  max-height: 400px;
  margin-right: 10px;
  margin-left: 5px;
  padding: 3px;
  left: 0;
  right: 0;
  transition: transform 150ms;
  position: relative;
  z-index: 4;
  overflow-y: auto;
}
.reference .link a {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.reference::before {
  content: " ";
  border: 8px solid transparent;
  border-left: 8px solid var(--spark);
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 100%;
}
.selecting {
  margin-left: 50px;
}
.checkbox {
  margin-top: -1px;
  margin-left: -50px;
  margin-bottom: 3px;
  margin-right: 18px;
  border-radius: 5px;
  align-self: stretch;
  background: var(--color2);
  display: flex;
  justify-content: center;
  align-items: center;
}
a.disabled {
  opacity: 0.3;
}
.button-bar {
  width: 100vw;
  z-index: 2;
  background: var(--spark);
  align-items: center;
}
#app header.title a {
  padding: 15px;
}
#app header.title .button-bar a {
  padding: 10px 15px;
}
#app header.title .button-bar i {
  padding: 0;
  font-size: 16px;
  color: var(--spark_text);
}
#app header.title .button-bar .previous-scene {
  padding: 7px;
  padding-left: 15px;
  width: auto;
  margin-right: auto;
  border-bottom-right-radius: 5px;
  font-size: 16px;
  align-self: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#app .previous-scene span {
  color: var(--spark_text);
}
.parent-title {
  padding: 0;
}
.parents {
  touch-action: pan-x;
  margin-left: 5px;
  /* margin-top: 50px; */
  scroll-margin: 50px;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-x: auto;
}
.parent {
  margin: 5px;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
  flex: 0 0 auto;
  width: 70vw;
  font-size: 17px;
  scroll-margin: 1px;
}
.sub-direction {
  margin: 5px 0;
  padding: 0;
}
.sub-direction .block {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sub-end {
  flex: 0 0 auto;
  border: 2px solid transparent;
  width: 30vw;
}
.label {
  vertical-align: center;
  display: inline-block;
  background: var(--color2);
  color: var(--color2_text);
  font-size: 13px;
  padding: 2px 5px;
  margin-top: -3px;
  margin-right: 5px;
  border-radius: 5px;
}
.down-icon svg {
  width: 40px;
  height: 40px;
}
.end-push {
  margin-bottom: 50px;
}
.word-count {
  position: absolute;
  bottom: 0;

  background: var(--spark);
  border-top: 2px solid var(--color1);
  border-left: 2px solid var(--color1);
  color: var(--spark_text);
  align-self: flex-end;
  flex: 0 0 auto;
  padding: 10px 15px;
  border-radius: 15px 0 0 0;
  z-index: 10;
}
.not-tiny {
  display: inline-block;
  margin-left: 5px;
}
.focused {
  border-color: var(--color3);
}
.mini-direction {
  margin-left: 5px;
  margin-bottom: 5px;
  margin-right: 25%;
  padding: 5px;
}
</style>

<template>
  <div v-if="!scene" :key="`loading-${sceneId}`" class="flex-column flex-center flex-align-center">
    <div v-if="status === 'error'" class="flex-column flex-center flex-align-center">
      <p>Error loading this scene</p>
      <router-link :to="{ name: 'story', params: { story: story.id } }" class="button">
        Go Back
      </router-link>
    </div>
    <div v-else class="flex-column flex-center flex-align-center">
      <p>{{ $t("app_status.loading") }}</p>
      <router-link :to="{ name: 'story', params: { story: story.id } }" class="button">
        Cancel
      </router-link>
    </div>
  </div>
  <div
    :key="sceneId"
    @keydown.capture="handleKeydown"
    @focus.capture="handleClickDirection"
    class="scene column no-scroll"
    v-else
  >
    <app-header title="scene" v-model:modelValue="open" class="scene-header">
      <back-button />
      <!-- <router-link
        :to="{name: 'story', params: {story: story.id}}">
        <i class="fa fa-arrow-left" />
      </router-link> -->
      <input
        v-model="scene.title"
        @input="saveScene"
        :placeholder="$t('scene.scene-title')"
        title="This is only visible to the story editor (you!)"
        class="scene-title"
      />
      <transition name="pop">
        <div v-if="status" class="status-indicator nowrap">
          <i class="fa fa-sync fa-spin" /> {{ status }}
        </div>
      </transition>
      <a
        class="dot"
        :class="scene.color"
        href="#"
        id="scene-color"
        @click.prevent="showSceneColor = !showSceneColor"
      />

      <template v-slot:extra>
        <div class="button-bar row space-between">
          <router-link
            class="previous-scene"
            v-if="parents.length == 1"
            :to="{
              name: 'scene',
              params: { story: story.id, id: parents[0].from.id },
              query: { direction: parents[0].id },
            }"
          >
            <i class="fa fa-arrow-circle-left" />
            <span class="not-tiny">{{ $t("scene.previous") }}</span>
          </router-link>
          <a
            class="previous-scene"
            v-else-if="parents.length > 1"
            href="#"
            @click.prevent="showIncoming = !showIncoming"
          >
            <i
              id="links-popover"
              class="fa fa-arrow-circle-left"
              :class="showIncoming ? 'fa-rotate-270' : ''"
            />
            <span class="not-tiny">{{ $t("scene.previous") }}</span>
          </a>
          <div class="stretch" />
          <div class="row static">
            <template v-if="!selecting">
              <a href="#" @click.prevent="showTutorials = true" class="open-tutorials">
                <i class="fa fa-question-circle" />
              </a>
              <a href="#" @click.prevent="handlePaste()" v-if="hasClipboard">
                <i class="fa fa-clipboard" />
              </a>
              <a href="#" @click.prevent="handleStartSelect">
                <i class="fa fa-check-square-o" />
              </a>
              <a @click.prevent="handleShowCommands(true)">
                <i class="fa fa-terminal" />
              </a>
              <router-link
                v-if="story.scenes.length > 1"
                :to="{
                  name: 'scene-search',
                  params: { story: story.id },
                  query: { back: $route.path },
                }"
              >
                <i class="fa fa-list" />
              </router-link>
              <router-link
                :to="{ name: 'acts', params: { story: story.id }, query: { scene: scene.id } }"
                class="overview-link"
              >
                <i class="fa fa-globe" />
              </router-link>
              <router-link
                :to="{ name: 'read', params: { story: story.id } }"
                class="read-story-link"
                title="continue reading"
                aria-label="Continue reading"
              >
                <i class="fa fa-eye" />
              </router-link>
              <a
                @click.prevent="handleStartHere"
                title="read from this scene"
                aria-label="Read from this scene"
              >
                <i class="fa fa-eye" /><i class="fa fa-arrow-right" />
              </a>
            </template>
            <template v-else>
              <a to="" @click.prevent="selecting = false">
                <i class="fa fa-close" />
              </a>
              <a to="" @click.prevent="handleCutSelected">
                <i class="fa fa-cut" />
              </a>
              <a to="" @click.prevent="handleCopySelected">
                <i class="fa fa-copy" />
              </a>
              <a
                href="#"
                v-if="hasClipboard"
                :class="{ disabled: selected.length !== 1 }"
                @click.prevent="handlePaste()"
              >
                <i class="fa fa-clipboard" />
              </a>
              <a href="" @click.prevent="handleDeleteSelected">
                <i class="fa fa-trash" />
              </a>
            </template>
          </div>
        </div>
      </template>
    </app-header>

    <sidebar-detail v-model:modelValue="open" :keep-sidebar-open="false">
      <template v-slot:sidebar>
        <div class="scene-sidebar static">
          <ul>
            <li class="flex-column flex-left">
              <help-message name="scene-microcosm">
                {{ $t("scene.scene-microcosm-tutorial") }}
              </help-message>
              <label class="padded">
                <input
                  type="checkbox"
                  v-model="scene.microcosm"
                  @input="update('microcosm', $event.target.checked)"
                />
                This scene starts a microcosm
              </label>
            </li>
            <li>
              <a href="#" @click.prevent="$refs.tips.openModal()">
                <i class="fa fa-question" /> {{ $t("scene.show-mini-tutorials") }}
              </a>
            </li>
            <li>
              <a href="#" class="delete-scene-option" @click.prevent="deleteScene">
                <i class="fa fa-trash" /> {{ $t("scene.delete-this-scene") }}
              </a>
            </li>
          </ul>
        </div>
      </template>

      <template v-slot>
        <div class="parents row scrollsnap-x scrollsnap-prox" v-if="parents.length > 0">
          <div
            v-for="(parent, idx) of parents"
            :key="`parent_${idx}`"
            class="parent mellow scrollsnap-item"
          >
            <router-link
              class="parent-title"
              :to="{ name: 'scene', params: { story: story.id, id: parent.from.id } }"
            >
              <b>{{ parent.from.title || sceneGetText(parent.from) }}</b>
            </router-link>
            <router-link
              v-for="(sub, sidx) of parent.from.directions.slice(
                parent.index - 2,
                parent.index + 1,
              )"
              :key="`parent_${idx}_sub_${sidx}`"
              :to="{
                name: 'scene',
                params: { story: story.id, id: parent.from.id },
                query: { direction: sub.id },
              }"
              class="sub-direction"
            >
              <div v-if="sub.type === 'text'" class="block">
                <span class="label label-info">{{ $t(`directionType.${sub.type}`) }}</span>
                <span>{{ sub.content }}</span>
              </div>
              <div v-else-if="sub.type === 'choice'" class="block">
                <span class="label label-info">
                  <i class="fa fa-hand-o-right" v-if="sub.id === parent.id" />
                  {{ $t(`directionType.${sub.type}`) }}
                </span>
                <span>{{ sub.label }}</span>
              </div>
              <div v-else class="block">
                <span class="label label-info">{{ $t(`directionType.${sub.type}`) }}</span>
              </div>
            </router-link>
            <div class="center down-icon">
              <vi name="chevrondown" />
            </div>
          </div>
          <div class="sub-end" />
        </div>
        <div class="parents" v-else>
          <!-- used to push directions down -->
        </div>

        <!-- editor -->
        <tip-of-the-day ref="tips" />
        <div class="direction-list no-scroll scrollsnap-prox" v-if="scene" ref="directions">
          <!-- color change -->
          <scene-color-popup
            v-model:value="showSceneColor"
            :story="story"
            @color="updateColor"
            target="#scene-color"
          />

          <!-- incoming links -->
          <popover
            v-model:value="showIncoming"
            color-theme="spark"
            target="#links-popover"
            :includeCloseButton="false"
          >
            <div class="scroll left">
              <router-link
                :to="{
                  name: 'scene',
                  params: { story: story.id, id: link.from.id },
                  query: { direction: link.id },
                }"
                v-for="(link, idx) of parents"
                :key="idx"
              >
                <span>
                  <i class="fa fa-arrow-circle-left" />
                  {{ link.from.title || sceneGetText(link.from).substring(0, 100) }} </span
                ><br />
                <span v-if="link.label"
                  ><i class="fa fa-hand-o-right user-choice-icon" /> {{ link.label }}</span
                >
              </router-link>
            </div>
          </popover>

          <div class="references" :style="`transform: translate(0, ${targetY}px);`">
            <div v-if="direction && references" class="reference">
              <div v-for="ref of references" :key="ref.id" class="link">
                <router-link :to="ref.link">
                  <b>{{ ref.type }}</b> {{ ref.label }}
                </router-link>
              </div>
            </div>
          </div>

          <div @click.capture="handleClickDirection" class="directions">
            <p v-if="scene.act" class="info">
              This is an Act starting point: <br />
              - Setup starts by making choices with different tags<br />
              - If tags are the same, the start will be randomized
            </p>

            <DraggableList
              class="stage"
              group="scenes"
              ref="directions"
              :startIndex="startDirectionIndex"
              v-model:items="scene.directions"
              @update:items="handleMoveDirection"
            >
              <template #default="{ item: direction, index: idx }">
                <direction
                  :key="`${direction.type}-${direction.id}`"
                  :scroll="scroll"
                  :index="idx"
                  :ref="`d${direction.id}`"
                  :count="scene.directions.length"
                  :story="story"
                  :scene="scene"
                  :editing="direction.id == directionId"
                  :is-grouped="groupedDirectionIds.indexOf(direction.id) > -1"
                  class="item"
                  :class="{
                    selecting,
                    selected: selected.indexOf(idx) > -1,
                    focused: direction.id == directionId,
                    [`direction-${direction.id}`]: true,
                  }"
                  v-model:value="scene.directions[idx]"
                  @update:value="saveScene"
                  @move="handleMove(idx, $event)"
                  @createAbove="insertDirection($event, idx)"
                  @create="insertDirection($event, idx + 1)"
                  @editing="closeOthers"
                  @remove="removeDirection"
                >
                  <label class="checkbox padded static" v-if="selecting">
                    <input type="checkbox" v-model="selected" :value="direction.id" />
                  </label>
                </direction>
              </template>
              <template #footer> </template>
            </DraggableList>
            <div v-if="scene.microcosm" class="mini-direction mellow flex-row">
              <div class="padded flex-static"><i class="fa fa-chevron-left" /></div>
              <div class="padded">Returns to catalyst</div>
            </div>
            <insert-direction
              :title="$t('scene.add-direction')"
              ref="bottom_insert"
              class="direction helper"
              :story="story"
              :scene="scene"
              :show-before="false"
              @create="insertDirection"
            />
          </div>
          <div class="references two"></div>
        </div>
        <div v-else>No scene?</div>
      </template>
    </sidebar-detail>
    <div v-if="optionsStore.showWordCount" class="word-count keyboard-crunch">
      {{ $t("scene.wordcount-words", wordCount, { count: wordCount.toLocaleString() }) }}
    </div>

    <command-search-modal
      v-if="showCommands"
      :story="story"
      :scene="scene"
      :direction="direction"
      @show="handleShowCommands"
      @update:value="handleCommand"
    />

    <tutorial-popup
      v-if="showTutorials"
      @show="handleShowTutorials"
      @input="handleTutorialSelected"
    />
  </div>
</template>

<script>
// import Choices from "./scene/choices.vue";
import { nanoid } from "nanoid";
import sortby from "sort-by";
// import DraggableList from '@morphosis/base/components/DraggableList.vue';
import { useOptionStore } from "@/stores/options";
import { timer } from "@/utils/timer";
import DraggableList from "@morphosis/base/components/LargeDraggableList.vue";
import { useToasterStore } from "@morphosis/base/stores/toaster";
import { mapStores } from "pinia";
import CommandSearchModal from "../components/command_search_popup.vue";
import Direction from "../components/direction.vue";
import TutorialPopup from "../components/editor/tutorial_popup.vue";
import InsertDirection from "../components/insert_direction.vue";
import SceneColorPopup from "../components/scene_color_popup.vue";
import SceneSyncMixin from "../mixins/scene_sync_mixin";

export default {
  name: "scene",
  mixins: [SceneSyncMixin],
  components: {
    // Choices,
    InsertDirection,
    DraggableList,
    Direction,
    SceneColorPopup,
    CommandSearchModal,
    TutorialPopup,
  },
  props: {
    story: { type: Object },
    id: { type: String },
  },
  head() {
    if (this.story) {
      return { title: this.story.title };
    }
    return { title: "Loading..." };
  },
  data() {
    return {
      status: null,
      edit: null,
      open: false,
      scenes: [],
      editing: null,
      showIncoming: false,
      showSceneColor: false,
      showTutorials: false,
      showCommands: false,
      // showSearch: false,
      selecting: false,
      selected: [],
      hasClipboard: false,
      directionId: null,
      startDirectionIndex: 0,
      targetY: 0,
      scroll: 0,
    };
  },
  computed: {
    groupedDirectionIds() {
      const retval = [];
      let inGroup = false;
      let groupType = null;
      for (const direction of this.scene.directions) {
        if (direction.type === "group") {
          inGroup = true;
          groupType = null;
        } else if (inGroup) {
          if (groupType === null) {
            groupType = direction.type;
            retval.push(direction.id);
          } else if (groupType !== direction.type) {
            inGroup = false;
          } else {
            retval.push(direction.id);
          }
        }
      }
      return retval;
    },
    wordCount() {
      return this.scenes.map((s) => s.wc || 0).reduce((a, b) => a + b, 0);
    },
    sceneId() {
      return this.id;
    },
    scene() {
      if (this.story) {
        let scene = this.scenes.find((s) => {
          return s.id === this.sceneId;
        });
        if (!scene) {
          scene = this.scenes.find((s) => {
            return s.id == `${this.sceneId}`;
          });
        }
        return scene;
      }
      return null;
    },
    direction() {
      if (this.scene && this.directionId) {
        return this.scene.directions.find((d) => d.id == this.directionId);
      }
      return null;
    },
    references() {
      if (this.direction) {
        const refs = [];
        const tags = (this.direction.effects || []).filter((a) => a).map((e) => e.tag);
        for (const tag of tags) {
          const storyTag = this.story.tags.find((t) => t.id === tag);
          if (storyTag) {
            const tagId = `tag-${storyTag.id}`;
            if (storyTag && !refs.find((r) => r.id === tagId)) {
              refs.push({
                id: tagId,
                type: "tag",
                link: { name: "tags", params: { story: this.story.id, id: storyTag.id } },
                label: storyTag.name,
              });
            }
          }
        }
        if (refs.length > 0) {
          return refs;
        }
      }
      return null;
    },
    player() {
      return this.$root.player;
    },
    parents() {
      const retval = [];
      this.scenes.forEach((s) => {
        const directions = s.directions.filter((sub) => sub.scene == this.scene.id);
        for (const choice of directions) {
          const copy = this.copy(choice);
          copy.from = s;
          copy.index = s.directions.findIndex((d) => d.id === choice.id);
          retval.push(copy);
        }
      });
      return retval;
    },
    ...mapStores(useOptionStore, useToasterStore),
  },
  watch: {
    async $route() {
      this.open = false;
      this.showIncoming = false;
      if (this.story) {
        this.scenes = await this.story.rel_scenes.list(); // sceneList();
      }
    },
    async scene(nv, ov) {
      if (this.__save) {
        console.log("saving previous scene", ov.id);
        await this.saveSceneNow(true, ov);
      }
      if (!this.scene) {
        console.log("scene not found", this.scene, this.sceneId);
        this.status = "error";
      }
    },
    sceneId() {
      if (this.scene) {
        this.put(`scene_marker_${this.story.id}`, this.sceneId);

        if (this.scene.directions.length === 0) {
          this.scene.directions.push({ id: 1, type: "text", content: "" });
        }
        this.scrollToTopDirection();
        this.beginScrolling();
      }
    },
    directionId() {
      const el = this.$el.querySelector(`#direction-${this.directionId}`);
      if (el) {
        const cont = this.$el.querySelector(`.directions`);
        const box = el.getBoundingClientRect();
        const contBox = cont.getBoundingClientRect();
        this.targetY = box.y - contBox.y;
      }
    },
  },
  async mounted() {
    if (!this.sceneId) {
      const start = await this.story.getStartScene();
      if (start) {
        this.$router.replace({ name: "scene", params: { story: this.story.id, id: start.id } });
      } else {
        this.toasterStore.add({ message: "Could not open the starting scene" });
      }
    } else {
      this.hasClipboard = !!(await this.readCache("clipboard"));
      this.setupSyncListener();
      this.scenes = await this.story.rel_scenes.list(); // sceneList();

      // find scroll direction
      const directionId = this.$route.query.direction;
      const focusedDirection = this.scene.directions.findIndex((d) => d.id == directionId);
      if (focusedDirection > -1) {
        this.directionId = focusedDirection.id;
        this.startDirectionIndex = focusedDirection;
        console.log("focus on", focusedDirection);
        await timer(100);
        await this.scrollToTopDirection();
      }
    }

    this.keyListener = this.handleKeydown.bind(this);
    document.addEventListener("keydown", this.keyListener);
    document.addEventListener("keyup", this.keyListener);
    this.beginScrolling();
  },
  activated() {
    this.beginScrolling();
  },
  deactivated() {
    this.endScrolling();
  },
  unmounted() {
    document.removeEventListener("keydown", this.keyListener);
    document.removeEventListener("keyup", this.keyListener);
    this.endScrolling();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.__save) {
      await this.saveSceneNow(true);
    }
    next();
  },
  methods: {
    async beginScrolling() {
      this.endScrolling();
      await this.$nextTick();
      this.scrollElement = this.$el.querySelector(".scroll");
      if (this.scrollElement) {
        this.scrollListener = () => {
          this.scroll = this.scrollElement.scrollTop;
        };
        this.scrollElement.addEventListener("scroll", this.scrollListener);
      }
    },
    endScrolling() {
      if (this.scrollElement) {
        this.scrollElement.removeEventListener("scroll", this.scrollListener);
      }
    },
    async saveScene() {
      console.warn("saveScene");
      this.scroll = this.scroll + 0.000001;
      if (!this.scene) {
        return false;
      }

      clearTimeout(this.__save);
      this.__save = setTimeout(async () => {
        if (!this.status) {
          this.saveSceneNow();
        } else {
          this.saveScene();
        }
      }, 7 * 1000);
    },
    async saveSceneNow(saveJsonNow = false, prevScene = null) {
      clearTimeout(this.__save);
      this.__save = null;

      const scene = prevScene || this.scene;
      scene.updated = new Date().toISOString();

      this.status = this.$t("story_form.saving");
      // await new Promise(r => setTimeout(r, 10000));
      await scene.save();
      // await this.story.freezeAudio();
      await this.story.save(saveJsonNow === true);
      await this.upload(scene, true);

      if (!saveJsonNow) {
        this.status = this.$t("scene.saved");
        await new Promise((r) => setTimeout(r, 300));
      }

      this.status = null;
    },
    async scrollToTopDirection() {
      await this.$nextTick();
      await new Promise((r) => setTimeout(r, 60));
      const directionId = this.$route.query.direction;
      const selector = `.direction-${directionId}`;

      if (window.innerWidth > 600) {
        let next = null;
        if (directionId) {
          next = this.$el.querySelectorAll(`${selector} [tabindex]`);
        } else if (this.$refs.directions) {
          next = this.$el.querySelectorAll(".item:first-child .direction [tabindex]");
        }
        if (next) {
          for (const n of next) {
            if (n.tabindex !== "-1") {
              n.focus();
            }
          }
        }
      }

      if (directionId) {
        for (let x = 0; x < 10; x += 1) {
          const direction = this.$el.querySelector(selector);
          if (direction) {
            console.log("found it", direction);
            direction.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            break;
          } else {
            console.log("waiting for direction to appear", this.startDirectionIndex, selector);
            this.startDirectionIndex -= 1;
            await timer(1000);
          }
        }
      } else if (this.$refs.directions) {
        const box = this.$refs.directions.getBoundingClientRect();
        this.$el.querySelector(".scroll").scrollTo({
          top: box.top - 95,
          behavior: "smooth",
        });
      }
    },
    update(prop, value) {
      console.warn("update", prop, value);
      this.scene[prop] = value;
      this.saveScene();
    },
    updateColor(color) {
      this.showSceneColor = false;
      this.scene.color = color;
      this.saveScene();
    },
    handleStartSelect() {
      this.selecting = true;
      this.selected = [];
    },
    async handleCopySelected() {
      let clipboard = await this.readCache("clipboard");
      if (!clipboard || confirm(this.$t("scene.clipboard-is-not-empty-clipped"))) {
        this.copyDirections();
        this.selecting = false;
        this.selected = [];
        this.hasClipboard = true;
      }
    },
    async handleCutSelected() {
      let clipboard = await this.readCache("clipboard");
      if (!clipboard || confirm(this.$t("scene.clipboard-is-not-empty-clipped"))) {
        if (confirm(this.$t("scene.cut-all-selected-directions"))) {
          this.copyDirections();
          this.deleteDirections();
          this.selecting = false;
          this.selected = [];
          this.hasClipboard = true;
          this.saveScene();
        }
      }
    },
    handleDeleteSelected() {
      if (confirm(this.$t("scene.delete-all-selected-directions"))) {
        this.deleteDirections();
        this.selecting = false;
        this.selected = [];
        this.saveScene();
      }
    },
    async handlePaste(at = 0) {
      const clipboard = await this.readCache("clipboard");
      console.log("pasting clipboard", clipboard);
      if (clipboard) {
        let insertAt = at;
        if (this.selected.length === 1) {
          insertAt = this.scene.directions.findIndex((d) => d.id === this.selected[0]) + 1;
        }

        for (const direction of clipboard) {
          direction.id = this.scene.directions.reduce((a, d) => Math.max(a, d.id + 1), 1);
          this.scene.directions.splice(insertAt, 0, direction);
          insertAt += 1;
        }

        await this.writeCache("clipboard", null);
        this.hasClipboard = false;
        this.selecting = false;
        this.selected = [];
        this.saveScene();
      }
    },
    handleShowTutorials(e) {
      if (!e) {
        this.showTutorials = false;
      }
    },
    async handleTutorialSelected(e) {
      this.$root.tutorialId = e;
    },
    async copyDirections() {
      const clipboard = [];
      const temp = this.selected.map((id) => ({
        id,
        index: this.scene.directions.findIndex((d) => d.id === id),
      }));
      temp.sort(sortby("index"));
      for (const { id } of temp) {
        const idx = this.scene.directions.findIndex((d) => d.id === id);
        clipboard.push(this.scene.directions[idx]);
      }
      await this.writeCache("clipboard", this.copy(clipboard));
    },
    deleteDirections() {
      for (const i of this.selected) {
        const idx = this.scene.directions.findIndex((d) => d.id === i);
        this.scene.directions.splice(idx, 1);
      }
    },
    closeOthers(v) {
      this.editing = v;
    },
    async deleteScene() {
      if (confirm(this.$t("scene.delete-this-scene-warning"))) {
        const idx = this.story.scenes.findIndex((s) => s == this.scene.id);
        if (idx > -1) {
          const promises = [];
          for (const s of this.scenes) {
            if (s.id !== this.scene.id) {
              const matches = s.directions.filter((d) => d.scene == this.scene.id);
              for (const d of matches) {
                delete d.scene;
              }
              s.updated = new Date().toISOString();
              s.save();
              if (this.story.cloudSync) {
                promises.push(s.push());
              }
            }
          }

          this.story.scenes.splice(idx, 1);
          this.story.updated = new Date().toISOString();
          promises.push(this.story.saveAndPush());

          if (this.story.cloudSync) {
            promises.push(this.scene.pushDelete());
          }
          promises.push(this.scene.delete());
        }
        this.$router.push({ name: "story", params: { story: this.story.id } });
      }
    },
    removeDirection(id) {
      const idx = this.scene.directions.findIndex((d) => d.id === id);
      if (idx > -1) {
        this.scene.directions.splice(idx, 1);
        this.saveScene();
      }
    },
    handleEditText() {
      this.edit = "text";
    },
    handleSaveText() {
      this.saveScene();
      this.edit = null;
    },
    handleMove(idx, d) {
      const [instance] = this.scene.directions.splice(idx, 1);
      if (instance) {
        this.scene.directions.splice(idx + d, 0, instance);
      }
      this.scene.directions = this.copy(this.scene.directions.slice());
      this.saveScene();
    },
    async insertDirection(type, index = -1, extras = {}) {
      const idx = index;
      let id = this.scene.directions.reduce((a, d) => Math.max(a, (d.id || 0) + 1), 1);
      let object = { id, type, ...extras };
      if (type == "text" && !object.state) {
        object.state = "default";
      } else if (type == "choice") {
        object.scene = "";
      }
      let directions = this.copy(this.scene.directions);
      if (idx == -1) {
        directions.push(object);
      } else {
        directions.splice(idx, 0, object);
      }
      this.scene.directions = directions;
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      const selector = `#direction-${object.id}`;
      const el = this.$el.querySelector(`${selector} [tabindex="0"]`);
      if (el) {
        el.focus();
        el.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
      }

      this.saveScene();
      this.$root.emitInternal("story:update", {
        action: "direction-create",
        story: this.story.id,
        scene: this.scene.id,
        direction: object,
        index: idx,
      });
    },
    handleClickDirection(e) {
      try {
        this.directionId = e.target.closest(".direction").dataset.id;
      } catch (ex) {
        // it's ok
      }
    },
    handleKeydown(e) {
      this.handleClickDirection(e);
      this.previousTarget = e.target;
      if (e.ctrlKey && e.key === "p") {
        if (/key/.test(e.type)) {
          e.preventDefault();
        }
        this.showCommands = true;
      }
    },
    handleShowCommands(value) {
      this.showCommands = value;
      if (!value && this.previousTarget) {
        this.previousTarget.focus();
      }
    },
    async handleCommand(value) {
      const cmd = value.cmd || value.id;
      if (cmd === "create-text-for") {
        const index = this.scene.directions.findIndex((d) => d.id == this.directionId);
        this.insertDirection("text", index > -1 ? index + 1 : undefined, value.params);
      } else if (cmd === "direction-create") {
        const index = this.scene.directions.findIndex((d) => d.id == this.directionId);
        this.insertDirection(value.params, index > -1 ? index + 1 : undefined);
      } else if (cmd === "direction-delete") {
        if (this.direction && confirm(this.$t("direction.delete-this-direction"))) {
          const prev = this.scene.directions.indexOf(this.direction) - 1;
          this.removeDirection(this.direction.id);
          if (prev > -1) {
            await this.$nextTick();
            this.directionId = this.scene.directions[prev].id;
            const el = this.$el.querySelector(`#direction-${this.directionId} [tabindex="0"]`);
            if (el) {
              el.focus();
            }
          }
        }
      } else if (this.direction && cmd === "direction-duplicate") {
        const index = this.scene.directions.findIndex((d) => d.id == this.directionId);
        const dup = this.copy(this.direction);
        dup.id = this.scene.directions.reduce((a, d) => Math.max(a, (d.id || 0) + 1), 1);
        if (value.params.notext) {
          dup.content = "";
        }
        this.scene.directions.splice(index + 1, 0, dup);
        this.saveScene();

        await this.$nextTick();
        this.directionId = dup.id;
        const el = this.$el.querySelector(`#direction-${this.directionId} [tabindex="0"]`);
        if (el) {
          el.focus();
        }
      } else if (cmd === "scene-duplicate") {
        const directions = this.copy(this.scene.directions);
        let title = this.scene.title;
        if (title) {
          if (/_\d+$/.test(title)) {
            let num = 0;
            [title, num] = title.split("_");
            title = title + "_" + (parseInt(num, 10) + 1);
          } else {
            title += "_1";
          }
        }
        const scene = await this.story.createScene({
          title: title,
          color: this.scene.color,
          directions: directions,
        });
        await this.saveScene();
        this.$router.push({ name: "scene", params: { story: this.story.id, id: scene.id } });
      } else if (cmd === "move") {
        this.handleMove(value.params);
      } else if (cmd === "previous") {
        const prev = this.$el.querySelector(".previous-scene");
        if (prev) {
          prev.click();
        }
      } else if (cmd === "set-character") {
        this.$root.emitInternal("command", {
          cmd,
          scene: this.scene.id,
          direction: this.direction.id,
        });
      } else if (this.direction && cmd === "set-effect") {
        if (!this.direction.effects) {
          this.direction.effects = [];
        }
        this.direction.effects.push({ value: "", tag: null, op: ":" });
        this.$refs[`d${this.directionId}`][0].handleChangeEditTab("effects");
      } else if (this.direction && cmd === "set-requirement") {
        if (!this.direction.effects) {
          this.direction.effects = [];
        }
        this.direction.effects.push({ value: "", tag: null, op: "#" });
        this.$refs[`d${this.directionId}`][0].handleChangeEditTab("requirements");
      } else if (this.direction && cmd === "formula-effect") {
        if (!this.direction.effects) {
          this.direction.effects = [];
        }
        this.direction.effects.push({ value: "", tag: null, op: "<f>" });
        this.$refs[`d${this.directionId}`][0].handleChangeEditTab("effects");
      } else if (this.direction && cmd === "formula-test") {
        if (!this.direction.effects) {
          this.direction.effects = [];
        }
        this.direction.effects.push({ value: "", tag: null, op: "<t>" });
        this.$refs[`d${this.directionId}`][0].handleChangeEditTab("requirements");
      } else if (this.direction && cmd === "roll-dice") {
        if (!this.direction.effects) {
          this.direction.effects = [];
        }
        // get or create dice
        const label = `D${value.params}`;
        let tag = this.story.tags.find((t) => t.name === label);
        if (!tag) {
          tag = { id: nanoid(), name: label, type: "tag" };
          this.story.tags.push(tag);
        }
        this.direction.effects.push({
          value: `${label} = floor(random(${value.params}) + 1)`,
          tag: null,
          op: "<f>",
        });
      } else if (cmd === "split") {
        const index = this.scene.directions.findIndex((d) => d.id === this.direction.id);
        const directions = this.scene.directions;
        let title = this.scene.title;
        if (title) {
          if (/_\d+$/.test(title)) {
            let num = 0;
            [title, num] = title.split("_");
            title = title + "_" + (parseInt(num, 10) + 1);
          } else {
            title += "_1";
          }
        }
        const scene = await this.story.createScene({
          title: title,
          color: this.scene.color,
          directions: directions.slice(index),
        });
        this.scene.directions = directions.slice(0, index);
        this.scene.directions.push({
          id: this.scene.directions.reduce((a, b) => Math.max(a, b.id) + 1, 0),
          type: "redirect",
          scene: scene.id,
        });
        await this.saveScene();
        this.$router.push({ name: "scene", params: { story: this.story.id, id: scene.id } });
      } else if (cmd === "export") {
        await this.saveSceneNow();
        const story = await this.loadFullStory(this.story.id);
        const storyJson = new Blob([JSON.stringify(story, null, 4)], { type: "application/json" });
        this.downloadFile(storyJson, `${story.title}.json`);
      } else if (cmd === "fill-debug") {
        let index = this.scene.directions.reduce((a, d) => Math.max(a, (d.id || 0) + 1), 1);
        let direction = null;
        const bacon =
          "Bacon ipsum dolor amet filet mignon boudin kevin, pancetta strip steak cupim prosciutto picanha shoulder ham hock tongue pork chop. Pig boudin shoulder pork belly. Rump kielbasa pork chop beef. Shank salami boudin biltong bacon pig short loin chuck ham hock. Alcatra shankle strip steak, meatball flank jerky corned beef pastrami turkey ground round. Cupim hamburger tongue flank cow. Porchetta brisket ham hock rump salami sausage, buffalo andouille jowl bacon beef ribs landjaeger prosciutto tri-tip.".split(
            / /g,
          );
        bacon.concat(bacon.slice());
        for (let x = 0; x < 300; x += 1) {
          direction = {
            id: index,
            type: "text",
            content:
              "" + x + " " + bacon.slice(0, Math.floor(Math.random() * bacon.length)).join(" "),
          };
          index += 1;
          this.scene.directions.push(direction);
        }
        this.saveScene();

        await this.$nextTick();
        this.directionId = direction.id;
        const el = this.$el.querySelector(`#direction-${this.directionId} [tabindex="0"]`);
        if (el) {
          el.focus();
        }
      } else {
        console.error("unknown command", value);
      }
    },
    handleStartHere() {
      const key = `story_${this.story.id}`;
      let player = this.$root.fetch(key);
      if (!player) {
        player = {};
      }
      player.choices = [
        { id: -1, type: "redirect", scene: this.scene.id, tags: {}, from: { scene: -1, idx: -1 } },
      ];
      this.$root.put(key, player);
      this.$router.push({ name: "read", params: { story: this.story.id } });
    },
    handleMoveDirection(e) {
      this.saveScene();
    },
  },
};
</script>
