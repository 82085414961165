<template>
  <transition-group
    appear
    :name="name"
    :css="false"
    :tag="tag"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot></slot>
  </transition-group>
</template>

<script lang="ts" setup>
import { computed } from "vue";

function nextFrame() {
  return new Promise((r) => {
    requestAnimationFrame(r);
  });
}

const props = withDefaults(
  defineProps<{
    tag?: string;
    name: string;
    mode?: string;
    delay?: number;
    removeDelay?: number;
  }>(),
  { tag: "div", delay: 100, removeDelay: 0 }
);

let queue = Promise.resolve();

const delay = computed(() => {
  if (typeof props.delay == "number") {
    return props.delay;
  }
  return parseInt(props.delay) || 0;
});

function isElementInViewport(el: HTMLElement) {
  let rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top <=
      (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */ &&
    rect.right <=
      (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
  );
}
async function apply(el: HTMLElement, type: string, delay: number, done: Function) {
  queue = queue.then(async () => {
    // reveal the element
    el.classList.remove("hide");

    // if in view, animate it
    if (isElementInViewport(el)) {
      // frame 2
      await nextFrame();
      el.classList.remove(`${props.name}-${type}`);
      el.classList.add(`${props.name}-${type}-to`);

      // listen for end
      let _listener = async () => {
        await nextFrame();
        el.classList.remove(`${props.name}-${type}-active`);
        el.classList.remove(`${props.name}-${type}-to`);

        el.removeEventListener("transitionend", _listener);
      };
      el.addEventListener("transitionend", _listener, true);

      // wait for next element
      await new Promise((r) => setTimeout(r, delay));
      done();
    } else {
      // not in view, don't animate
      el.classList.remove(`${props.name}-${type}-active`);
      el.classList.remove(`${props.name}-${type}`);
      done();
    }
  });
}
function applyOneFrame(el: HTMLElement, classname: string) {
  el.classList.add(classname);
}

// --------
// ENTERING
// --------
function beforeEnter(el: HTMLElement) {
  if (props.delay) {
    el.classList.add("hide");
    el.classList.add(`${props.name}-enter`);
    el.classList.add(`${props.name}-enter-active`);
  }
}
// the done callback is optional when
// used in combination with CSS
function enter(el: HTMLElement, done: Function) {
  if (props.delay) {
    apply(el, "enter", delay.value, done);
  } else {
    done();
  }
}
function afterEnter(el: HTMLElement) {}

// --------
// LEAVING
// --------
function beforeLeave(el: HTMLElement) {
  if (props.removeDelay) {
    el.classList.add(`${props.name}-leave`);
    el.classList.add(`${props.name}-leave-active`);
  }
}
// the done callback is optional when
// used in combination with CSS
function leave(el: HTMLElement, done: Function) {
  if (props.removeDelay) {
    el.style.transition = `all ${props.removeDelay}ms`;
    apply(el, "leave", props.removeDelay, done);
  } else {
    el.style.display = "none";
    done();
  }
}
function afterLeave(el: HTMLElement) {}
</script>
