import { Color } from "three";

export const WHITE = new Color("white");
export const BLACK = new Color("black");

export function invertLum(color: Color | string): Color {
  let vector = null;
  if (color instanceof Color) {
    vector = color.clone();
  } else {
    vector = new Color(color);
  }
  const hsl = { h: 0, s: 0, l: 0 };
  vector.getHSL(hsl);
  const c1 = [vector.r, vector.g, vector.b, vector.a];
  if (hsl.l >= 0.5) {
    vector.offsetHSL(0, 0, -0.5);
  } else {
    vector.offsetHSL(0, 0, 0.5);
  }
  return vector;
}

export function lerpColors(label: string, color1: Color, color2?: Color): Record<string, string> {
  const rootVars: Record<string, string> = {};
  if (!color2) {
    color2 = invertLum(color1);
    2;
  }
  rootVars[`--${label}`] = `#${color1.getHexString()}`;
  if (label !== "text") {
    rootVars[`--${label}_text`] = `#${color2.getHexString()}`;
  }
  const gradient = 100;
  const step = 10;
  for (let x = step; x < gradient; x += step) {
    const intermediate = color1.clone();
    intermediate.lerp(color2, x / gradient);
    // const darkened = intermediate.clone().lerpHSL(WHITE, 0.1);
    // const lightened = intermediate.clone().lerpHSL(WHITE, 0.1);
    rootVars[`--${label}_${x}`] = `#${intermediate.getHexString()}`;
    if (label !== "text") {
      rootVars[`--${label}_text_${x}`] = `#${invertLum(intermediate).getHexString()}`;
    }
  }

  return rootVars;
}
