<template>
  <form class="vv-form" @submit.prevent="submit">
    <div
      v-for="{ type, name, label, help, attrs, ...extra } in props.schema"
      :key="name"
      class="vv-field"
      :class="{
        'vv-invalid': !!errors[name],
      }"
    >
      <label :for="name" class="vv-label">{{ label || name }}</label>
      <div class="vv-inner">
        <ErrorMessage :name="name" class="vv-messages" />
        <div class="vv-widget">
          <template v-if="fieldRegistry[type]">
            <component
              :is="fieldRegistry[type].comp"
              :id="name"
              :name="name"
              v-bind="{ ...fieldRegistry[type].attrs, ...attrs, ...extra }"
            />
          </template>
          <Field v-else :type="type" :id="name" :name="name" v-bind="attrs" />
        </div>
        <div v-if="help" class="vv-help">
          {{ help }}
        </div>
      </div>
    </div>
    <div v-if="options.actions.length > 0" class="vv-actions">
      <Button v-for="action of options.actions" type="submit" :name="action.value">
        {{ action.label }}
      </Button>
    </div>
  </form>
</template>

<script setup lang="ts">
import copy from "@virgodev/bazaar/functions/copy";
import { dottedGet, dottedSet } from "@virgodev/bazaar/functions/dotted_get";
import Button from "primevue/button";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { computed, watch } from "vue";
import { debounce } from "../../utils/debounce";
import { registry, type VvField } from "./registry";

export interface VvFormOptions {
  actions: { label: string; value: string }[];
}
const defaultOptions = { actions: [{ label: "Save", value: "default" }] };
const props = withDefaults(
  defineProps<{
    modelValue: any;
    schema: VvField[];
    options?: VvFormOptions;
  }>(),
  { options: { actions: [] } }
);
const emits = defineEmits(["submit", "update:values"]);

const validationSchema = computed(() => {
  const retval = {};
  for (const field of props.schema) {
    if (field.validation) {
      dottedSet(retval, field.name, field.validation);
    }
  }
  return retval;
});

const fieldRegistry = computed(() => {
  return registry;
});

const options = computed(() => {
  const opts = copy(props.options);
  for (const key in defaultOptions) {
    if (opts[key] === undefined) {
      opts[key] = defaultOptions[key];
    }
  }
  return opts;
});

const initialValues = computed(() => {
  const defaults = copy(props.modelValue || {});
  for (const field of props.schema) {
    const existing = dottedGet(defaults, field.name);
    if ([undefined, null].includes(existing) && field.default) {
      dottedSet(defaults, field.name, field.default);
    }
  }
  return defaults;
});

const { meta, values, errors, handleSubmit, setValues, controlledValues, isFieldDirty } = useForm({
  initialValues: initialValues,
  validationSchema: validationSchema.value,
});

watch(
  () => props.modelValue,
  () => setValues(props.modelValue),
  { deep: true }
);

watch(
  values,
  async (newval, oldval) => {
    if (await debounce("form-updated-values", 300)) {
      emits("update:values", values);
    }
  },
  { deep: true }
);

const submit = handleSubmit(async (values) => {
  emits("submit", values);
});
</script>

<style scoped>
.vv-form {
  display: grid;
  max-width: 600px;
  padding: 1em;
  gap: 1em;
  grid-template-columns: auto auto;
}

.vv-field {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 2;
}
.vv-label {
  grid-column: 1/1;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 0.75em;
}
.vv-inner {
  justify-self: stretch;
  align-self: center;
  text-align: left;
  grid-column: 2/2;
}
.vv-messages {
  margin-left: 0.35em;
  text-align: left;
  padding: calc(var(--spacing) * 2);
  color: var(--spark-text);
  background: var(--spark);
  border-radius: var(--softness);
}
.vv-invalid .vv-widget {
  /* border: 2px dashed red; */
}
.vv-help {
  padding-top: 0.5em;
  text-align: left;
  opacity: 0.75;
  font-size: 90%;
}
.vv-actions {
  grid-column: 2/2;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}
@media (max-width: 600px) {
  .vv-form {
    grid-template-columns: auto;
  }
  .vv-field,
  .vv-inner,
  .vv-actions {
    grid-template-columns: subgrid;
    grid-column: 1/1;
  }

  .vv-label {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
}
</style>
