<template>
  <div v-if="action === 'create' || action === 'edit'">
    <div v-if="objectForm" class="flex-static">
      <div class="flex-row flex-left align-center m-1-t">
        <a href="#" @click.prevent="action = 'list'"> <i class="fa fa-chevron-left" /> Cancel </a>
        <div class="flex-stretch" />
        <Button v-if="action === 'edit'" @click="handleDelete" class="delete-button icon">
          <i class="pi pi-trash" />
        </Button>
      </div>
      <VvDynamicForm
        v-if="action === 'create' || instance"
        v-model="instance"
        :schema="objectForm"
        :options="{ actions: [{ label: 'Save', value: 'save' }] }"
        @submit="handleSubmit"
      />
      <div v-else>Loading...</div>
    </div>
    <div v-else>Sorry, no form was configured to create this object</div>
  </div>
  <div v-else>
    <div class="flex-static flex-row justify-left align-center gap-2 toolbar">
      <Searcher
        v-if="searchFields.length > 0"
        :config="fuseConfig"
        :options="props.modelValue"
        v-model:selected="instance"
        v-model:results="results"
        v-model:value="searchText"
      />
      <div class="flex-row flex-static gap-1 count-box">
        <span v-if="searchFields.length > 0 && results">{{ results.length }} / </span>
        <span>{{ props.modelValue.length }}</span>
      </div>
      <div v-if="canShowGrid && props.allowList" class="flex-row switch-box">
        <a v-if="style === 'list'" href="#" @click.prevent="style = 'grid'">
          <i class="pi pi-table" />
        </a>
        <a v-if="style === 'grid'" href="#" @click.prevent="style = 'list'">
          <i class="pi pi-list" />
        </a>
      </div>
      <div class="flex-stretch" />
      <Button
        class="create-button icon"
        @click="
          selected = undefined;
          action = 'create';
        "
      >
        <i class="fa fa-plus" />
      </Button>
    </div>
    <div v-if="style === 'grid'" class="grid flex-static gap-1">
      <div v-for="row of cells" class="grid-item flex-row align-bottom justify-space-between p-0">
        <AdminCell v-if="row.grid.image" :cell="row.grid.image" />

        <div class="flex-row align-center flex-space-between gap-1 p-1-x">
          <AdminCell :cell="row.grid.first" class="text-left" />
          <Button
            @click="
              action = 'edit';
              selected = row.item.id;
            "
            class="edit-button icon"
          >
            <i class="fa fa-pencil" />
          </Button>
          <Button v-if="props.pick" @click="emits('pick', row.item)" class="icon">
            <i class="fa fa-chevron-right" />
          </Button>
        </div>
      </div>
    </div>
    <div v-else class="table flex-static">
      <div class="header row">
        <div v-for="header of headers" class="cell text-left">
          {{ header }}
        </div>
        <div class="cell"></div>
      </div>
      <div v-if="props.modelValue.length === 0" class="row">
        <div class="cell" :class="`colspan${headers.length + 1}`">
          <i>Nothing here yet</i>
        </div>
      </div>
      <div v-for="row of cells" class="row">
        <div v-for="cell of row.cells" class="cell text-left">
          <AdminCell :cell="cell" />
        </div>
        <div class="cell flex-row">
          <Button
            @click="
              action = 'edit';
              selected = row.item.id;
            "
            class="icon"
          >
            <i class="fa fa-pencil" />
          </Button>
          <Button v-if="props.pick" @click="emits('pick', row.item)" class="icon">
            <i class="fa fa-chevron-right" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends {id: string}">
import { nanoid } from "nanoid";
// import InputText from "primevue/inputtext";

import copy from "@morphosis/base/functions/copy";
import { debounce } from "@morphosis/base/utils/debounce";
import { FuseResult } from "fuse.js";
import Button from "primevue/button";
import { computed, onMounted, ref, watch } from "vue";
import { VvField } from "../forms/registry";
import VvDynamicForm from "../forms/VvDynamicForm.vue";
import AdminCell from "./AdminCell.vue";
import Searcher from "./Searcher.vue";

export interface Cell {
  value: string;
  modifier?: string;
  argv: string[];
  params: Record<string, string>;
}

export interface AdminConfig {
  searchFields?: string[];
  listDisplay?: { label?: string; source: string }[];
  form: any;
  fields?: Record<string, (a: T) => string | Promise<string>>[];
}

const props = withDefaults(
  defineProps<{
    modelValue: any[];
    config?: AdminConfig;
    action?: string;
    selected?: string;
    pick?: boolean;
    allowGrid?: boolean;
    allowList?: boolean;
  }>(),
  { defaultStyle: "list", allowGrid: true, allowList: false }
);
const emits = defineEmits(["pick", "update:modelValue", "update:action", "update:selected"]);

const style = ref("list");
const action = ref(props.action);
const selected = ref(props.selected);
const searchText = ref("");
const instance = ref<T>();
const results = ref<FuseResult<T>[]>();
const fuseConfig = computed(() => {
  return {
    keys: searchFields.value,
    includeMatches: true,
    findAllMatches: true,
  };
});

const canShowGrid = computed(() => {
  const images = listDisplay.value.filter((h) => /\|image/.test(h.source));
  return props.allowGrid && images.length > 0;
});

const searchFields = computed(() => {
  return props.config?.searchFields || [];
});

const objectForm = computed<VvField[]>(() => {
  return props.config?.form;
});

const headers = computed(() => {
  let retval = [];
  try {
    retval = listDisplay.value.map((field) => {
      return field.label || field.source.split("|")[0].replace(/[\-_]/g, " ");
    });
  } catch (e) {
    console.error("Invalid listDisplay", listDisplay.value);
  }
  return retval;
});

const headerCount = computed(() => {
  return headers.value.length;
});

const listDisplay = computed(() => {
  return props.config?.listDisplay || [];
});

const cells = ref<{ item: T; grid: { cell?: Cell; first: Cell }; cells: Cell[] }[]>([]);

watch([results, listDisplay], async () => {
  if (await debounce("admin-list", 10)) {
    const retval = [];
    if (results.value) {
      for (const row of results.value) {
        const cells = await Promise.all(
          listDisplay.value.map((display) => {
            return getCell(row, display.source);
          })
        );

        retval.push({
          item: row.item,
          grid: {
            image: cells.find((c) => c.modifier === "image"),
            first: cells.find((c) => !c.modifier),
          },
          cells,
        });
      }
    }
    cells.value = retval;
  }
});

watch([selected, () => props.modelValue], () => {
  if (selected.value) {
    instance.value = props.modelValue.find((i) => i.id === selected.value);
  } else {
    instance.value = undefined;
  }
});

watch([instance], () => {
  if (instance.value) {
    selected.value = instance.value.id;
  } else {
    selected.value = undefined;
  }
});

onMounted(async () => {
  if (canShowGrid.value) {
    style.value = "grid";
  }
  await window.app.ready;
  if (selected.value) {
    instance.value = props.modelValue.find((i) => i.id === selected.value);
  }
});

async function getCell(object: FuseResult<T>, field: string): Promise<Cell> {
  let name = field;
  let modifier = "";
  let params = {};
  let argv = [];
  if (/\|/.test(field)) {
    [name, modifier] = field.split("|");
    if (/:/.test(modifier)) {
      let stringParams = "";
      [modifier, stringParams] = modifier.split(":");
      for (const param of stringParams.split(",")) {
        if (/=/.test(param)) {
          const [key, value] = param.split("=");
          params[key] = value;
        } else {
          argv.push(param);
        }
      }
    }
  }
  let value = object.item[name];
  if (props.config.fields && props.config.fields[name]) {
    value = await props.config.fields[name](object.item);
  }
  return {
    value,
    modifier,
    params,
    argv,
  };
}

watch(action, () => {
  console.log("action", action.value);
  emits("update:action", action.value);
});
watch(selected, () => emits("update:selected", selected.value));

function toString(object: T) {
  return object.toString();
}

function handleSubmit(e) {
  console.log("submit", e);
  const list = copy<T[]>(props.modelValue);
  const newInst: Partial<T> = {};
  let id = selected.value;
  for (const key in copy<T>(e)) {
    if (/^__/.test(key)) {
      console.log(key, e[key]);
    } else {
      newInst[key] = e[key];
    }
  }
  if (instance.value) {
    const index = props.modelValue.findIndex((i) => i.id === instance.value.id);
    list[index] = {
      ...list[index],
      ...newInst,
    };
  } else {
    id = nanoid();
    newInst.id = id;
    list.push(newInst as T);
  }
  action.value = "";
  emits("update:modelValue", list);
  emits("update:action", "");
  emits("update:selected", id);
}

function handleDelete() {
  const list = copy<T[]>(props.modelValue);
  const index = list.findIndex((i) => i.id === props.selected);
  if (index > -1) {
    instance.value = undefined;
    list.splice(index, 1);
    action.value = "";
    emits("update:modelValue", list);
    emits("update:action", "");
    emits("update:selected", "");
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .toolbar {
    border: 1px dashed purple;
  }
  .toolbar:focus-within {
    border: 1px solid red;
  }
  .toolbar:focus-within .count-box,
  .toolbar:focus-within .switch-box {
    display: none;
  }
}

.grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.grid-item {
  position: relative;
  height: 200px;
}
.grid-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid-item > :deep(div) {
  height: 50px;
  flex: 0 0 auto;
}
.grid-item > div {
  height: 50px;
  background: rgba(0, 0, 0, 0.65);
  color: white;
}
.grid-item :deep(div) {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table {
  display: grid;
  grid-template-columns: repeat(v-bind("headerCount"), 1fr) auto;
}
.row {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span calc(v-bind("headerCount") + 1);
  background: var(--color1);
  color: var(--color1_text);
}
.row:nth-child(even) {
  background: var(--color1_10);
  color: var(--color1_text_10);
}
.cell {
  padding: 1em;
}
.header .cell {
  text-transform: uppercase;
}
.notActive {
  opacity: 0.65;
}
</style>
