<template>
  <div class="advanced flex-row flex-left flex-align-top flex-wrap padded">
    <help-message v-if="value.type === 'text'" name="autogrow-text-template-language" class="left">
      {{ $t("autogrow_text.help-for-advanced-editing-you-can-use-basic-templating") }}<br />
      <a href="https://twig.symfony.com/" target="_system">{{ $t("advanced.twig-templates") }}</a>
    </help-message>

    <div class="auto-form lined">
      <div class="form-row" v-if="['text', 'image'].indexOf(value.type) > -1">
        <label>Auto Advance:</label>
        <div class="checkbox flex-row flex-align-center">
          <input
            type="checkbox"
            :checked="value.auto_advance"
            @input="$emit('update', { auto_advance: $event.target.checked })"
          />
        </div>
      </div>

      <div class="form-row" v-if="['achievement', 'text'].indexOf(value.type) > -1">
        <label>Speed:</label>
        <div class="checkbox flex-row flex-align-center">
          <list-chooser
            :value="value.speed"
            :list="speeds"
            @update:value="$emit('update', { speed: $event })"
          >
            <template v-slot:empty>
              <div class="text-style empty">
                {{ $t("autogrow_text.style-choice--story-default") }}
              </div>
            </template>
            <template v-slot="{ object: speed }">
              <div class="text-speed">{{ speed.name }}</div>
            </template>
          </list-chooser>
        </div>
      </div>

      <div class="form-row" v-if="['achievement', 'text', 'choice'].indexOf(value.type) > -1">
        <label>Style:</label>
        <div class="checkbox flex-row flex-align-center">
          <style-chooser
            :value="value.style"
            :story="story"
            @update:value="$emit('update', { style: $event })"
          />
        </div>
      </div>

      <div class="form-row" v-if="['option'].indexOf(value.type) > -1">
        <label>{{ $t("advanced.shared-points") }}</label>
        <div class="flex-row flex-align-center">
          <autogrow-textarea
            :modelValue="points"
            @update:modelValue="$emit('update', { points: $event })"
          />
          <button class="accented" @click.prevent="$refs.formula.show()">
            <vi name="formula" />
          </button>
          <formula-popup
            ref="formula"
            :story="story"
            :value="points"
            @update:value="$emit('update', { points: $event })"
          />
        </div>
        <label>Min:</label>
        <div class="flex-row flex-align-center">
          <input
            type="number"
            step="1"
            min="0"
            placeholder="0"
            :value="value.min"
            @input="$emit('update', { min: parseInt($event.target.value) })"
          />
        </div>
        <label>Max:</label>
        <div class="flex-row flex-align-center">
          <input
            type="number"
            step="1"
            placeholder="1"
            :value="value.max"
            @input="$emit('update', { max: parseInt($event.target.value) })"
          />
        </div>
        <label>Step:</label>
        <div class="checkbox flex-row flex-align-center">
          <input
            type="number"
            step="1"
            placeholder="1"
            :value.number="value.step"
            @input="$emit('update', { step: parseInt($event.target.value) })"
          />
        </div>
      </div>

      <div v-if="useOverlayout && isVisible" class="form-row">
        <label for="weight">
          <vi :name="layoutDirection" />
          <span class="padded">Layout:</span>
        </label>

        <div class="flex-row flex-align-center">
          <i class="fa fa-step-forward padded" @click="cycleLayout" />
          <select
            name="weight"
            :value="value.layout"
            @change="$emit('update', { layout: $event.target.value })"
          >
            <option>bottom</option>
            <option>top</option>
            <option>left</option>
            <option>right</option>
            <option>over</option>
          </select>
        </div>
      </div>

      <div class="form-row" v-if="isVisible">
        <label class="block">
          <i class="fa fa-film" />
          <span class="padded">Animation:</span>
        </label>
        <div class="flex-column flex-center">
          <animation-chooser
            :story="story"
            :value="value.animationId"
            @update:value="updateAnimation($event)"
          />
        </div>
      </div>

      <div class="form-row form-align-center" v-if="['empty', 'choice'].indexOf(value.type) > -1">
        <label v-if="value.type === 'choice'">{{
          $t("advanced.generate-choices-from-list")
        }}</label>
        <label v-else>{{ $t("advanced.iterate-from-list") }}</label>
        <div class="padded">
          <tag-picker
            :story="story"
            :value="value.array"
            :extras="{
              $players: true,
            }"
            type="array"
            @update:value="$emit('update', { array: $event })"
          />
        </div>
      </div>

      <div class="form-row" v-if="['redirect', 'choice'].indexOf(value.type) > -1">
        <label>{{ $t("choice_content.microcosm") }} (Deprecated):</label>
        <div class="checkbox flex-row flex-align-center">
          <input
            type="checkbox"
            :checked="value.microcosm"
            @change="$emit('update', { microcosm: $event.target.checked })"
          />
          <div class="help">
            {{ $t("advanced.mark-scenes-as-microcosms-instead") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_SPEEDS } from "../../global";
import AnimationChooser from "../editor/animation_chooser.vue";
import StyleChooser from "../editor/style_chooser.vue";
import TagPicker from "../editor/tag_picker.vue";

export default {
  name: "AdvancedTab",
  components: {
    AnimationChooser,
    StyleChooser,
    TagPicker,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  computed: {
    points() {
      let retval = this.value.points;
      if (typeof retval === "object") {
        retval = 0;
      }
      return retval;
    },
    speeds() {
      return TYPE_SPEEDS;
    },
    settings() {
      return this.story.settings || {};
    },
    useOverlayout() {
      return this.settings.overlayout || this.$root.overlayout;
    },
    isVisible() {
      return ["text", "choice", "image", "background", "achievement"].indexOf(this.value.type) > -1;
    },
    effectiveStyle() {
      if (this.story) {
        let retval = this.story.styles.find((s) => s.id === this.value.style);
        if (!retval && this.value.type === "choice") {
          retval = this.story.styles.find((s) => s.default_choice);
        }
        if (!retval) {
          retval = this.story.styles.find((s) => s.default);
        }
        return retval;
      }
      return null;
    },
    layoutDirection() {
      return {
        bottom: "gamedown",
        left: "gameleft",
        top: "gameup",
        right: "gameright",
        over: "gameall",
        under: "gamenone",
      }[this.value.layout || "bottom"];
    },
  },
  methods: {
    updateAnimation(v) {
      let defaultAnimation = null;
      if (this.story.settings) {
        if (this.value.type === "choice") {
          defaultAnimation = this.story.settings.animationChoice;
        }
        defaultAnimation = defaultAnimation || this.story.settings.animation;
      }
      if (v === defaultAnimation) {
        this.$emit("update", { animationId: null });
      } else {
        this.$emit("update", { animationId: v });
      }
    },
    cycleLayout() {
      const choices = ["left", "top", "right", "over", "bottom"]; // under;
      const index = (choices.indexOf(this.value.layout) + 1) % choices.length;
      this.$emit("update", { layout: choices[index] });
    },
  },
};
</script>

<style scoped>
#app label {
  margin-top: 0;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
