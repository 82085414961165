<style scoped>
input[type="color"] {
  visibility: hidden;
}
.row {
  padding: 0 10px;
}
.color-list {
  margin: 5px;
}
.color-node {
  width: 15px;
  height: 15px;
  margin: 2.5px;
}
.active {
  border: 1px solid black;
  box-shadow: 0 0 1px 1px var(--spark);
}
input {
  font-size: 16px;
  margin: 0 4px;
  padding: 2px;
  border-radius: var(--softness) var(--softness) 0 0;
  border: 2px solid white;
  text-align: center;
}
</style>

<template>
  <popover v-if="props.visible" :value="true" @show="emits('update:visible', $event)">
    <div class="flex-row flex-wrap">
      <div class="p-2">
        <ColorWheel v-model:value="hsl" @update:value="updateFromHsl" :size="300" />
      </div>
      <div class="flex-column flex-static">
        <div class="centered">
          <input
            type="text"
            max-length="7"
            v-model="data.value"
            :style="{ background: data.value, color: inverted, opacity: props.opacity }"
          />
          <div class="color-list flex-row">
            <div v-for="(col, coli) of colors" :key="`col-${coli}`" class="flex-col">
              <div
                v-for="color2 of col"
                :key="color2"
                :style="{ background: color2 }"
                class="color-node"
                :class="{ active: data.value === color2 }"
                @click="pickColor(color2)"
              />
            </div>
          </div>
        </div>
        <label class="row" v-if="props.includeOpacity">
          <span class="static padded">{{ $t("color_dialog.opacity") }}</span>
          <input
            type="range"
            :value.number="props.opacity"
            @input="emits('update:opacity', parseFloat($event.target?.value))"
            min="0.0"
            max="1.0"
            step="0.1"
          />
        </label>
        <label class="row" v-if="props.includeNull">
          <span class="static padded">No color / transparent</span>
          <input type="checkbox" @input="removeColor" :checked="!data.value && data.value !== 0" />
        </label>
        <div class="flex-row flex-static">
          <div class="flex-stretch" />
          <button @click.prevent="emits('update:visible', false)">OK</button>
        </div>
      </div>
    </div>
  </popover>
</template>

<script lang="ts" setup>
import ColorWheel from "@morphosis/drawing-board/ColorWheel.vue";
import { Color } from "three";
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { invertLum } from "../functions/colors";
import Popover from "./Popover.vue";

const props = withDefaults(
  defineProps<{
    color: string;
    opacity: number;
    visible: boolean;
    includeOpacity: boolean;
    includeNull: boolean;
  }>(),
  { color: "#000000", visible: false, includeOpacity: false, includeNull: false }
);
const emits = defineEmits<{
  "update:color": [string];
  "update:visible": [boolean];
  "update:opacity": [number];
}>();

const data = reactive<{
  value: string;
  el?: HTMLElement;
}>({
  value: props.color,
});

const hsl = ref({ h: 0, s: 0, l: 0 });
const color = new Color();

const inverted = computed(() => {
  return `#${invertLum(data.value).getHexString()}`;
});
const colors = computed(() => {
  const MAX_HUES = 13;
  const MAX_STEPS = 5;
  const colors: string[][] = [];
  for (let h = 0; h < 2; h += 1) {
    const col: string[] = [];
    for (let l = 0; l <= MAX_STEPS; l += 1) {
      const l1 = l + h + h * MAX_STEPS;
      const l2 = MAX_STEPS * 2 + 1;
      const light = l1 / l2;
      const c = new Color(`hsl(0, 0%, ${Math.floor(light * 100)}%)`);
      col.push(`#${c.getHexString()}`);
    }
    colors.push(col.reverse());
  }
  for (let h = 0; h < MAX_HUES; h += 1) {
    const col: string[] = [];
    for (let l = 0; l <= MAX_STEPS; l += 1) {
      const hue = h / MAX_HUES;
      const p = l / (MAX_STEPS + 1);
      const light = p * 0.9 + 0.1;
      const c = new Color(`hsl(${hue * 360}, 100%, ${Math.floor(light * 100)}%)`);
      col.push(`#${c.getHexString()}`);
    }
    colors.push(col.reverse());
  }
  return colors;
});

// watch(
//   () => props.color,
//   () => {
//     console.log("color changed", props.color, data.value);
//     if (data.value !== props.color) {
//       data.value = props.color;
//       color.setStyle(props.color);
//       color.getHSL(hsl.value);
//     }
//   }
// );
watch(
  hsl,
  () => {
    color.setHSL(hsl.value.h, hsl.value.s, hsl.value.l);
  },
  { deep: true }
);

onMounted(() => {
  data.value = props.color;
  color.setStyle(data.value);
  color.getHSL(hsl.value);
  console.log("mounted", data.value, hsl.value);
});

async function updateFromHsl() {
  await nextTick();
  data.value = "#" + color.getHexString();
  updateColor();
}

async function updateColor() {
  emits("update:color", data.value);
}
async function removeColor() {
  if (props.includeOpacity) {
    emits("update:opacity", 0);
  }
  emits("update:color", null);
}
function pickColor(c: string) {
  data.value = c;
  color.setStyle(data.value);
  color.getHSL(hsl.value);
  updateColor();
}
</script>
