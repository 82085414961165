<template>
  <div
    class="area"
    :class="{ [name]: true, active }"
    :key="name"
    draggable="false"
    @scroll="handleScroll"
  >
    <div v-if="bottom" class="flex-stretch no-touch" />
    <!-- <transition-queue name="fade" class="flex-static" :delay="150"> -->
    <reader-content
      v-for="(block, index) of filteredBlocks"
      :key="block.id"
      :story="story"
      :picks="picks"
      :block="block"
      :option-choices="optionChoices"
      :first="index === 0"
      :last="index === filteredBlocks.length - 1"
      :ref="`block_${block.id}`"
      :idle="modelValue"
      :tags="tags"
      @user-input="$emit('user-input', $event)"
      @choice="handleChoice"
      @finished="handleFinished($event, index)"
      @option="handleOptionChange"
    />
    <!-- </transition-queue> -->
    <height-transition
      v-if="hasOptions && Object.keys(optionPoints).length > 0"
      class="flex-static"
    >
      <div class="option-remaining block">
        <span v-for="key in optionPoints">
          {{ optionPoints[key] - (optionUses[key] || 0) }} /
          {{ key }}
        </span>
      </div>
    </height-transition>
    <div v-if="showExplorePrompt" class="flex-row flex-center flex-align-center flex-static">
      <button @click="$emit('explore')">
        <i class="fa fa-compass" />
        {{ exploreChoice.prompt || "Explore Background" }}
      </button>
    </div>
    <div v-if="moreIndicator" @click="scrollToBottom" class="more">
      <i class="fa fa-chevron-down" />
    </div>
    <span class="scroll-snap" ref="bottom" />
    <ScrollSticker />
  </div>
</template>

<script>
import { runFormula } from "../../utils/tag_effects";
import ScrollSticker from "./ScrollSticker.vue";
import ReaderContent from "./content.vue";
import HeightTransition from "./height_transition.vue";

export default {
  name: "ReaderArea",
  components: {
    ReaderContent,
    HeightTransition,
    ScrollSticker,
  },
  props: {
    modelValue: Boolean, // idle
    story: Object,
    name: String,
    activeName: String,
    picks: Object,
    blocks: Array,
    tags: Object,
    bottom: { type: Boolean, value: false },
    canExplore: { type: Boolean, default: false },
  },
  data() {
    return {
      optionChoices: {},
      optionPoints: {},
      moreIndicator: false,
    };
  },
  computed: {
    filteredBlocks() {
      return this.blocks.filter((block) => block.type !== "choice" || !block.glyphs?.length);
    },
    exploreChoice() {
      return this.blocks.find((block) => block.type === "choice" && block.glyphs?.length > 0);
    },
    lastBlock() {
      if (this.blocks.length > 0) {
        return this.blocks[this.blocks.length - 1];
      }
      return null;
    },
    showExplorePrompt() {
      return (
        this.canExplore &&
        !!this.exploreChoice &&
        ["choice", "choice-timer"].includes(this.lastBlock.type)
      );
    },
    optionUses() {
      const retval = {};
      for (const choice in this.optionChoices) {
        const option = this.blocks.find((b) => b.id == choice);
        if (option && option.points) {
          if (!retval[option.points]) {
            retval[option.points] = 0;
          }
          retval[option.points] += this.optionChoices[choice];
        }
      }
      return retval;
    },
    active() {
      return this.name === this.activeName;
    },
    blocking() {
      return this.blocks.filter((b) => ["timer"].indexOf(b.type) === -1);
    },
    hasOptions() {
      return this.blocks.filter((b) => b.type === "option").length > 0;
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue && this.hasOptions) {
        this.handleOptions();
      }
    },
    blocks: {
      handler(ov, nv) {
        if (this.filteredBlocks.length > 0) {
          this.startScroller();
        }
        this.moreIndicator = false;
      },
      deep: true,
    },
    blocking: {
      handler(ov, nv) {
        if (this.blocking.length > 0) {
          // console.log(' -- ', false, this.blocking.map(b => b.type));
          this.$emit("update:modelValue", false);
        } else {
          // console.log(' -- ', true, this.blocking.map(b => b.type));
          this.$emit("update:modelValue", true);
        }
      },
      deep: true,
    },
  },
  unmounted() {
    this.stopScroller();
  },
  methods: {
    async handleOptions() {
      await new Promise((r) => setTimeout(r, 100));
      if (this.modelValue && this.hasOptions) {
        this.optionChoices = {};

        const points = {};
        for (const option of this.blocks.filter((b) => b.type === "option" && b.points)) {
          points[option.points] = true;
        }
        for (const key in points) {
          points[key] = runFormula(this.story, key, this.tags);
        }
        this.optionPoints = points;

        this.$emit("show-next-button", true);
      }
    },
    handleOptionChange(packet) {
      const option = packet.option;

      if (!this.optionChoices[option.id]) {
        this.optionChoices[option.id] = 0;
      }

      const step = option.step || 1;
      const uses = option.points ? this.optionUses[option.points] || 0 : null;
      const value = this.optionChoices[option.id] || 0;
      const euses = uses - value;
      let max = option.max || 1;
      let min = option.min || 0;
      // if (option.points) {
      //   // max removes already used points from other options
      //   // and adds back the ones used from this option (else they would be double counted)
      //   max = Math.min(max || 1, option.points - uses + value);
      // }

      console.log(step, min, max, value, uses);

      // comparet to target value
      let target = value;
      if (packet.action === "toggle") {
        if (this.optionChoices[option.id]) {
          target = 0;
        } else if (step <= max || 1) {
          target = step;
        }
      } else if (packet.action === "up") {
        target = value + step;
      } else if (packet.action === "down") {
        target = value - step;
      }

      let valid = true;
      const stepMin = 0 + (step < 0) ? step : 0;

      // does it in the range
      let range = [option.min || 0, max].sort();
      if (target > range[1]) {
        valid = false;
        console.log("target above max", target, range);
      }
      if (target < range[0]) {
        valid = false;
        console.log("target below min", target, range);
      }

      // is it limited in points
      if (target + euses > option.points) {
        valid = false;
        console.log("target out of points", target, euses, stepMin, option.points);
      }

      // TODO: if is valid
      if (valid) {
        this.optionChoices[option.id] = target;
        const valued = {};
        for (const key in this.optionChoices) {
          if (this.optionChoices[key] > 0) {
            valued[key] = this.optionChoices[key];
          }
        }
        this.$emit("option-choices", valued);
      }
    },
    handleChoice(e) {
      if (this.modelValue) {
        this.$emit("choice", e);
      } else {
        console.log("ignored choice while typing/animating");
      }
    },
    handleFinished(e, index) {
      if (this.filteredBlocks.length - 1 === index) {
        this.$emit("update:modelValue", true);
        if (this.active) {
          this.$emit("finished");
        }
      }
    },
    handleScroll(e) {
      clearTimeout(this._handleScroll);
      this._handleScroll = setTimeout(() => {
        const bottom = window.innerHeight - 50;
        const ref = this.$refs.bottom;
        const rect = ref.getBoundingClientRect();
        this.moreIndicator = !(rect.top >= 50 && rect.bottom <= bottom + 25);
      }, 100);
    },
    hurry() {
      for (const block of this.filteredBlocks) {
        const ref = this.$refs[`block_${block.id}`];
        if (ref && ref.length > 0 && ref[0].hurry) {
          ref[0].hurry();
        }
      }
    },
    scrollToBottom() {
      // scroll to bottom
      let scrolled = false;
      // const typer = text.querySelector('.p .typer-inner');
      // if (typer) {
      //   // 30 is the margin
      //   this.textHeight = Math.max(this.textHeight, typer.scrollHeight + 30);
      // }
      const box = this.$el.getBoundingClientRect();

      if (box.bottom - 10 <= box.height) {
        // text.scrollTop = Math.floor(text.scrollHeight);
        this.$refs.bottom.scrollIntoView({ block: "end", inline: "end" });
        scrolled = true;
      }
      if (!scrolled) {
        this.stopScroller();
      }
    },
    startScroller() {
      this.__scroller = requestAnimationFrame(() => {
        this.startScroller();
      });
      // this.scrollToBottom();
    },
    stopScroller() {
      cancelAnimationFrame(this.__scroller);
    },
  },
};
</script>

<style scoped>
#app .area {
  display: flex;
  min-height: 0;
  /* max-height: 100%; */
  /* max-height: calc(min(100%, 100vh)); */
  max-width: calc(100vw);
  text-align: left;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  overflow-x: hidden;
  overflow-y: auto;
  /* scrollsnap makes some unable to scroll */
  /* scroll-snap-type: y proximity; */
  user-select: none;
  position: relative;
}

#app .more {
  position: fixed;
  right: calc(50vw - 25px);
  bottom: -4px;
  animation: blinky 1200ms linear infinite;
  background: var(--color2_text);
  color: var(--color2_text);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 10%, 100% 10%, 50% 90%);
  z-index: 3;
}
#app .more::after {
  position: fixed;
  right: calc(50vw - 25px);
  bottom: -4px;
  background: var(--color2);
  color: var(--color2);
  content: ".";
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 10%, 100% 10%, 50% 90%);
  transform: translate(-3px, -3px);
}

@keyframes blinky {
  0% {
    opacity: 0.95;
  }
  20% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.95;
  }
  100% {
    opacity: 0.95;
  }
}

#app .area > .flex-stretch {
  min-height: 15px;
  flex: 2 2 auto;
}
#app .area > :last-child {
  scroll-snap-stop: normal;
  scroll-snap-align: end;
}
.no-touch {
  /* pointer-events: none; */
  /* touch-action: none; */
}
.option-remaining {
  padding: 5px;
  margin-top: -3px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 0 0 var(--softness) var(--softness);
  color: var(--spark_text_20);
  background: var(--spark_20);
  opacity: 0.8;
}
</style>
