<template>
  <div class="aura-editor">
    <label>
      Use Aura:
      <input type="checkbox" binary :checked="value?.disable !== false" @click="toggleOptions" />
    </label>
    <div
      v-if="value?.disable !== false"
      class="card mellow flex-row flex-wrap flex-center flex-align-center"
    >
      <div class="cont flex-static">
        <ImageAura :value="props.value || {}" :size="200" />
      </div>
      <div>
        <AutoForm
          @update:value="emits('update:value', $event)"
          :fields="fields"
          :value="props.value || {}"
          :sub="true"
          class="responsive"
        />
        <div class="flex-row flex-right">
          <button @click.prevent="clearOptions">Clear</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageAura from "@/components/character/ImageAura.vue";
import AutoForm from "@/components/forms/auto_form.vue";
import { i18n } from "@/i18n";
import { type Aura, type Character } from "@/stores/story_defs";
import copy from "@morphosis/base/functions/copy";
import { computed } from "vue";

const props = defineProps<{
  character?: Character;
  value?: Aura;
}>();
const emits = defineEmits(["update:value"]);

const fields = computed(() => {
  return [
    {
      name: "color",
      label: i18n.global.t("character.form-field-aura"),
      type: "color",
    },
    {
      name: "shine",
      label: i18n.global.t("aura-shine"),
      type: "color",
    },
    {
      name: "size",
      label: i18n.global.t("aura-shine-spoke-size"),
      type: "number",
      min: 0,
      step: 5,
      max: 100,
    },
    {
      name: "quantity",
      label: i18n.global.t("aura-shine-spokes"),
      type: "number",
      min: 1,
      max: Math.floor(360),
    },
    {
      name: "speed",
      label: i18n.global.t("aura-shine-rotation-speed"),
      options: [
        { name: "none", value: "0s" },
        { name: "25 seconds", value: "25s" },
        { name: "50 seconds", value: "50s" },
        { name: "100 seconds", value: "100s" },
        { name: "10 minutes", value: "600s" },
      ],
    },
  ];
});

function toggleOptions() {
  const retval = copy<Aura>(props.value || {});
  if (retval.disable === undefined) {
    retval.disable = false;
  } else {
    retval.disable = !retval.disable;
  }
  emits("update:value", retval);
}

function clearOptions() {
  emits("update:value", undefined);
}
</script>

<style scoped>
.aura-editor {
  container: aura-editor / inline-size;
}
@container aura-editor (max-width: 300px) {
  .card {
    flex-direction: column !important;
  }
}
.cont {
  overflow: hidden;
  border-radius: var(--softness);
  width: 200px;
  height: 200px;
  max-width: 200px;
  background: repeating-linear-gradient(-45deg, #666 0px 15px, #333 15px 30px);
  position: relative;
}
</style>
