<template>
  <div
    class="reader-layout story-read"
    :style="{
      '--story-color': settings.color || '#222222',
      '--story-bg': settings.background || '#D3D3D3',
    }"
  >
    <!-- <div class="players flex-static flex-column">
      {{ code }} / {{ isServer }} / {{ isClient }}
      <div v-for="(player, id) in state" :key="id">
        {{ player.username }}
      </div>
    </div> -->

    <div class="top-buttons">
      <slot name="top-buttons"></slot>
    </div>
    <div class="overlays flex-static">
      <slot name="overlays"></slot>
    </div>
    <reader-backgrounds
      :story="story"
      :backgrounds="areas.bottom.bgs"
      :glyphs="glyphs"
      v-model:exploring="canExplore"
      ref="bgs"
      @glyph-click="handleChoice"
    />
    <div
      v-if="canExplore && exploring"
      class="explorer"
      @pointermove="$refs.bgs.handlePan"
      @pointerdown="$refs.bgs.handlePan"
      @pointercancel="$refs.bgs.handlePan"
      @pointerup="$refs.bgs.handlePan"
      @wheel.prevent="$refs.bgs.handlePan"
      @click.prevent="$refs.bgs.handleClick"
    ></div>
    <div :class="{ subdued: canExplore && exploring }" class="reader-content flex-stretch">
      <div class="backdrop" v-if="overlay" @click="overlay != 'over' ? (overlay = null) : null" />
      <div v-if="overlay === 'over' && areas.over.blocks.length > 0" class="area overlay">
        <reader-backgrounds :story="story" :backgrounds="areas.over.bgs" />
        <div class="flex-static align-top close" v-if="hasChoices">
          <a @click.prevent="overlay = null">&times;</a>
        </div>
        <reader-area
          name="over"
          ref="over"
          v-model:modelValue="areas.over.idle"
          @user-input="handleInput"
          @choice="handleChoice"
          @explore="exploring = true"
          :canExplore="canExplore"
          :picks="picks"
          :blocks="areas.over.blocks"
          :active-name="activeArea"
          :story="story"
        />
      </div>
      <div class="content-cols flex-row flex-stretch">
        <div
          v-if="areas.left.blocks.length > 0"
          :class="{ open: overlay === 'left' }"
          class="left-col flex-column flex-stretch"
        >
          <reader-backgrounds :story="story" :backgrounds="areas.left.bgs" />
          <div class="flex-static align-right close">
            <a @click.prevent="overlay = null">&times;</a>
          </div>
          <reader-area
            name="left"
            ref="left"
            v-model:modelValue="areas.left.idle"
            @user-input="handleInput"
            @choice="handleChoice"
            @explore="exploring = true"
            :canExplore="canExplore"
            :picks="picks"
            :blocks="areas.left.blocks"
            :active-name="activeArea"
            :story="story"
          />
        </div>
        <div class="center-col flex-column flex-stretch">
          <div class="top-col flex-stretch no-scroll" v-if="areas.top.blocks.length > 0">
            <reader-backgrounds :story="story" :backgrounds="areas.top.bgs" />
            <reader-area
              name="top"
              ref="top"
              v-model:modelValue="areas.top.idle"
              @user-input="handleInput"
              @choice="handleChoice"
              @explore="exploring = true"
              :canExplore="canExplore"
              :picks="picks"
              :blocks="areas.top.blocks"
              :active-name="activeArea"
              :story="story"
              :tags="tags"
            />
          </div>
          <reader-area
            name="bottom"
            ref="bottom"
            v-model:modelValue="areas.bottom.idle"
            @user-input="handleInput"
            @show-next-button="showNextButton = $event"
            @option-choices="optionChoices = $event"
            @choice="handleChoice"
            @explore="exploring = true"
            :canExplore="canExplore"
            :picks="picks"
            :blocks="areas.bottom.blocks"
            :active-name="activeArea"
            :story="story"
            :tags="tags"
            :bottom="true"
          />
          <transition name="slideup" appear>
            <div class="accented finished" v-if="finished">
              <slot :restartCmd="handleRestart">
                <AuthorPlug v-if="remoteApi" :show-user="true" :author="author" />
                <comments
                  v-if="remoteApi && story.fid"
                  :story="story.fid"
                  :remote-api="remoteApi"
                />
                <div class="the-end">
                  <button
                    aria-label="Exit story"
                    class="the-end-restart"
                    @click.prevent="$router.go(-1)"
                    v-if="$router"
                  >
                    <i class="fa fa-arrow-left" />
                    {{ t("layout.back") }}
                  </button>
                  <button class="the-end-restart flex-row gap-1" @click.prevent="handleRestart">
                    <i class="fa fa-refresh" />
                    <span>{{ t("layout.read-again") }}</span>
                  </button>
                  <slot name="restart-adornment"> </slot>
                </div>
              </slot>
            </div>
          </transition>
        </div>
        <div
          v-if="areas.right.blocks.length > 0"
          :class="{ open: overlay === 'right' }"
          class="right-col flex-column flex-stretch"
        >
          <reader-backgrounds :story="story" :backgrounds="areas.right.bgs" />
          <div class="flex-static align-left close">
            <a @click.prevent="overlay = null">&times;</a>
          </div>
          <reader-area
            name="right"
            ref="right"
            v-model:modelValue="areas.right.idle"
            @user-input="handleInput"
            @choice="handleChoice"
            @explore="exploring = true"
            :canExplore="canExplore"
            :picks="picks"
            :blocks="areas.right.blocks"
            :active-name="activeArea"
            :story="story"
          />
        </div>
      </div>
    </div>
    <div class="reader-footer" v-if="!finished" @click.prevent="hurry">
      <div class="flex-static indicators flex-row">
        <div class="sidebar-indicator">
          <transition-group appear name="quickfade" tag="div">
            <a v-if="areas.left.blocks.length > 0" key="left" @click.prevent="overlay = 'left'">
              <i class="fa fa-chevron-left" />
            </a>
            <a
              v-if="areas.right.blocks.length > 0"
              key="right"
              @click.prevent="overlay = overlay === 'right' ? null : 'right'"
            >
              <i class="fa fa-chevron-right" />
            </a>
          </transition-group>
        </div>

        <!-- <div v-if="canExplore && !exploring" class="explore-indicator">
          <button @click.prevent="exploring = true" class="icon">
            <i class="fa fa-compass" />
          </button>
        </div> -->

        <div v-if="canExplore && exploring" class="list-indicator">
          <button @click.prevent="exploring = false" class="icon">
            <i class="fa fa-chevron-up" />
          </button>
        </div>
      </div>
      <div class="flex-stretch flex-row">
        <!-- <div v-for="(value, name) in areas" :key="`is-idle-${name}`" class="debug-border1">
          {{ name }} {{ value.idle }}
        </div> -->
        <!-- <div class="debug-border1">picked: {{ hasYourPick }}</div>
        <div class="debug-border1">Choices: {{ choiceCount }}</div>
        <div class="debug-border1">Finished: {{ finished }}</div>
        <div class="debug-border1">isIdle: {{ isIdle }}</div>
        <div class="debug-border1" v-for="(area, name) in areas" :key="`block-count-${name}`">
          {{ name }}: {{ area.blocks.length }}
        </div> -->
        <!-- <span class="debug-border1">
          {{ isWaiting }} | has: {{ waiting.has }} needs: {{ waiting.needs }}
        </span> -->
        <!-- <span>{{ scene }} - {{ last.id }} - {{ finished }}</span> -->
        <!-- <span class="debug-border1">
          {{ choiceCount }}
        </span> -->
      </div>
      <div class="next" v-if="reader && choiceCount === 0">
        <transition-group name="quickfade" tag="div">
          <a
            key="next"
            aria-label="next"
            @click.prevent.stop="handleNext"
            v-show="isIdle && !isWaiting && !hasYourPick && needsNextButton"
          >
            <i class="fa fa-chevron-right" />
          </a>
          <span key="waiting" class="pick-count" v-if="isWaiting">
            {{ waiting.has.length }}
          </span>
          <span key="picks" class="pick-count" v-if="isIdle && hasYourPick">
            {{ Object.keys(picks).length }}
          </span>
        </transition-group>
      </div>
    </div>

    <blob-audio
      autoplay
      loop
      :volume="fadedMusicVolume"
      :blob="musicSrc"
      ref="music"
      :oncanplaythrough="musicOnCanPlayThrough"
    />
    <DocumentKeycapture @keypress="handleKeyEvent" />
  </div>
</template>

<script lang="ts">
import Comments from "@/components/comments.vue";
import { i18n } from "@/i18n";
import Media from "@/models/media";
import { App } from "@capacitor/app";
import DocumentKeycapture from "@morphosis/base/components/DocumentKeycapture.vue";
import debug from "debug";
import { mapStores } from "pinia";
import MultiplayerMixin from "../../mixins/multiplayer_mixin";
import DeferredList from "../../utils/deferred";
import Reader from "../../utils/reader";
import AuthorPlug from "../author_plug.vue";
import ReaderArea from "./area.vue";
import ReaderBackgrounds from "./background.vue";
import ReaderContent from "./content.vue";
import { useReaderStore } from "./store";

const log = debug("over");

function timer(t) {
  return new Promise((r) => setTimeout(r, t));
}

export default {
  name: "SceneReader",
  mixins: [MultiplayerMixin],
  components: {
    AuthorPlug,
    Comments,
    ReaderContent,
    ReaderArea,
    ReaderBackgrounds,
    DocumentKeycapture,
  },
  props: {
    story: Object,
    remoteApi: Function,
  },
  data() {
    const defaultSettings = {
      persistence: this.story.settings.stackDialog ? "scene" : "block",
    };
    return {
      pageActive: true,
      queue: new DeferredList(),
      picks: {},
      overlay: null,
      finished: false,
      waiting: { needs: [], has: [] },
      scene: null,
      reader: null,
      player: null,
      isIdle: false,
      audioVolume: 1,
      audioFade: 1,
      musicSrc: null,
      musicNextSrc: null,
      musicVolumeLocal: 1,
      musicVolume: 1,
      musicFade: 1,
      narrationVolume: 1,
      currentAudio: [],
      backgroundColor: null,
      backgroundImage: null,
      activeArea: "bottom",
      last: { id: -1 },
      areas: {
        top: { blocks: [], bgs: [], idle: true, settings: this.copy(defaultSettings) },
        bottom: { blocks: [], bgs: [], idle: true, settings: this.copy(defaultSettings) },
        left: { blocks: [], bgs: [], idle: true, settings: this.copy(defaultSettings) },
        right: { blocks: [], bgs: [], idle: true, settings: this.copy(defaultSettings) },
        over: { blocks: [], bgs: [], idle: true, settings: this.copy(defaultSettings) },
      },
      choiceCount: 0,
      showNextButton: false,
      optionChoices: null,
      exploring: false,
      canExplore: false,
      glyphs: [],
      tags: {},
      listeners: {
        idle: [],
      },
      musicOnCanPlayThrough: async function musicOnCanPlayThrough() {
        if (this.$refs?.music) {
          this.$refs.music.play();
        }
        await this.fadeMusic();
      }.bind(this),
    };
  },
  computed: {
    directionsNeedingNext() {
      return ["video", "text", "image", "achievement"];
    },
    needsNextButton() {
      const hasContent = Object.keys(this.areas).some((key) => {
        const area = this.areas[key];
        return area.blocks.length > 0;
      });
      return (
        this.showNextButton ||
        (hasContent &&
          Object.keys(this.areas).every((key) => {
            const area = this.areas[key];
            return (
              area.blocks.length === 0 ||
              this.directionsNeedingNext.indexOf(area.blocks[area.blocks.length - 1].type) > -1
            );
          }))
      );
    },
    hasYourPick() {
      return !!this.picks[this.$root.user.id];
    },
    hasChoices() {
      return this.reader && this.choiceCount > 0;
    },
    settings() {
      const retval = {
        background: "lightgrey",
        deferTimer: true,
      };
      for (const prop in this.story.settings) {
        retval[prop] = this.story.settings[prop];
      }
      return retval;
    },
    fadedMusicVolume() {
      return this.musicVolume * this.musicVolumeLocal * this.musicFade;
    },
    isClient() {
      return this.code && !this.$root.iamHosting;
    },
    isServer() {
      return this.code && this.$root.iamHosting;
    },
    /** is waiting for other players */
    isWaiting() {
      if (this.reader && this.code && this.waiting.needs.size > 0) {
        const player = this.reader.players.player(this.$root.user.id);
        if (player) {
          const party = this.reader.players.getParty(player.party);
          if (!party) {
            return true;
          } else {
            return this.waiting.has.some((g) => g === party.name);
          }
        } else {
          console.log("missing player?", this.reader.players.players);
        }
      }
      return false;
    },
    ...mapStores(useReaderStore),
  },
  watch: {
    areas: {
      handler() {
        let retval = true;
        for (const name in this.areas) {
          retval &= this.areas[name].idle;
        }
        clearTimeout(this.changeIsIdleTimeout);
        if (retval) {
          const speed = Math.max(this.story.settings?.typeSpeed || 30, 30);
          if (speed) {
            this.changeIsIdleTimeout = setTimeout(() => (this.isIdle = true), speed);
          } else {
            this.isIdle = true;
          }
        } else {
          this.isIdle = false;
        }
      },
      deep: true,
    },
    isIdle() {
      if (this.isIdle) {
        for (const listener of this.listeners.idle) {
          listener();
        }
        // console.log(' *> is idle');
      } else {
        // console.log(' <* is busy');
        this.exploring = false;
      }
    },
    currentAudio() {
      while (this.currentAudio.length > 10) {
        const audio = this.currentAudio.shift();
        this.disposeAudio(audio);
      }
    },
  },
  async mounted() {
    // move to it's own component
    this.musicVolume = this.fetch("music-volume", 1);
    this.audioVolume = this.fetch("audio-volume", 1);
    this.narrationVolume = this.fetch("narration-volume", 1);
    this.$root.onInternal("audio-volume", (e) => {
      this.audioVolume = e;
      this.put("audio-volume", e);
      for (const audio of this.currentAudio) {
        if (!audio.dataset.narration) {
          audio.volume = this.audioVolume;
        }
      }
    });
    this.$root.onInternal("music-volume", (e) => {
      this.musicVolume = e;
      this.put("music-volume", e);
    });
    this.$root.onInternal("narration-volume", (e) => {
      this.narrationVolume = e;
      this.put("narration-volume", e);
      for (const audio of this.currentAudio) {
        if (audio.dataset.narration) {
          audio.volume = this.narrationVolume;
        }
      }
    });
    this.gameEventListener = (event, data) => {
      if (this.isServer) {
        log(" *** server command", event, data);
      }
      if (this.isServer) {
        if (event === "player-next") {
          this.reader.next(data.user);
        } else if (event === "player-choice") {
          this.reader.choice(data.choice, data.user);
        } else if (event === "player-input") {
          this.reader.input(data.input, data.user);
        } else if (event === "player-options") {
          this.reader.options(data.options, data.user);
        }
      } else if (event === "reader") {
        log(" => remote event", event, data);
        this.handleReaderEvent(data);
      } else if (event === "restart") {
        this.$emit("restart");
      }
    };
    this.$root.onInternal("game-event", this.gameEventListener);

    if (this.remoteApi) {
      try {
        const author = await this.remoteApi("user_info", { id: this.story.uid });
        author.id = this.story.uid;
        if (!author.error) {
          this.author = author;
        }
      } catch (ex) {
        console.warn("failed to load user");
      }
    }

    if (this.code) {
      this.player = { tags: {}, choices: [], microcosms: [] };
      this.reader = new Reader(
        this.story,
        this.player,
        this.settings,
        this.handleReaderEvent,
        this.isClient,
      );
      this.readerStore.reader = this.reader;

      for (const id of this.$root.playerIds) {
        log("added player", id, this.state[id].username);
        this.reader.players.add(id, this.state[id].username, id === this.$root.user.id);
      }

      if (this.$root.iamHosting) {
        this.reader.reset();
        this.reader.start();
        window.reader = this.reader;
      }
    } else {
      this.player = this.loadPlayer(this.story);
      console.log("starting single player", this.copy(this.player));
      this.reader = new Reader(this.story, this.player, this.settings, this.handleReaderEvent);
      this.readerStore.reader = this.reader;

      // this.reader.reset();
      this.reader.start();
      window.reader = this.reader;
    }

    if (this.reader.scene) {
      this.$emit("update:sceneId", this.reader.scene.id);
    }
    this.queue.run();

    // listen for changes in the reader
    App.addListener("pause", () => {
      this.pageActive = false;
      for (const audio of this.currentAudio) {
        audio.pause();
      }
    });
    App.addListener("resume", () => {
      this.pageActive = true;
      for (const audio of this.currentAudio) {
        audio.play();
      }
    });

    this.appState = (await App.getState()).isActive;
  },
  beforeUnmount() {
    this.silenceAudio();
    App.removeAllListeners();
  },
  unmounted() {
    this.$root.offInternal("game-event", this.gameEventListener);
  },
  methods: {
    async playAndManageAudio(audio: HTMLAudioElement) {
      audio.onended = () => {
        this.disposeAudio(audio);
      };
      audio.oncanplaythrough = () => {
        if (this.pageActive && !audio.dataset.played) {
          audio.dataset.played = true;
          audio.play();
        }
      };
      this.currentAudio.push(audio);
    },
    async disposeAudio(audio: HTMLAudioElement) {
      audio.currentTime = 0;
      audio.pause();
      const index = this.currentAudio.findIndex((a) => a === audio);
      if (index > -1) {
        this.currentAudio.splice(index, 1);
      }
    },
    async waitForIdle() {
      if (this.isIdle) {
        return;
      }
      return new Promise((r) => {
        this.listeners.idle.push(r);
      });
    },
    async clearBlocks(scene = false) {
      this.glyphs = [];

      // TODO: save settings for each area
      // and observe those rules here
      for (const name in this.areas) {
        const area = this.areas[name];
        if (area.blocks.length > 0) {
          let shouldClear = true;

          if (area.settings.persistence === "scene") {
            shouldClear = scene;
          } else if (area.settings.persistence === "manual") {
            shouldClear = false;
          }

          if (shouldClear) {
            await this.forceClearBlocks(area);
          }
        }
      }
    },
    async forceClearBlocks(area) {
      // console.log(' -->> clear started', area);
      const len = area.blocks.length;
      area.blocks = area.blocks.slice(len - 3, len);
      while (area.blocks.length > 0) {
        area.blocks.shift();
        await timer(100);
      }
      // console.log(' <<-- clear ended', area);
    },
    async handleReaderEvent({ changed, value, party }) {
      // console.log('->', changed, value, (value ? value.layout : null) || 'bottom');
      if (this.isServer) {
        this.$root.playerSendEvent("reader", { changed, value, party });
      }

      const player = this.reader ? this.reader.players.player(this.$root.user.id) : { party: 0 };

      let inParty = true;
      if ((party || party === 0) && this.reader) {
        if (player && player.party !== party) {
          inParty = false;
        }
      }

      // console.log('changed', changed);

      if (changed === "begin") {
        log("begin");
        this.reader.next();
      } else if (changed === "player") {
        await this.$nextTick();
        this.savePlayer(this.story);
      } else if (changed === "players") {
        // console.log('---- player changed ----');
        for (const player of value) {
          // console.log('players', player.id, player.username, player.party);
          const local = this.reader.players.players.find((p) => p.id === player.id);
          local.party = player.party;
        }
      } else if (changed === "waiting") {
        // this.waiting[`${value.scene}-${value.direction}`] = value;
        if (this.scene === value.scene && this.last.id === value.direction) {
          this.waiting = value;
        }
      } else if (changed === "tags") {
        log("tags", this.player, JSON.stringify(this.player));
        this.$emit("update:tags", { player: this.player.tags, tags: value });
        this.tags = value;
      } else if (changed === "settings") {
        this.queue.add(async () => {
          console.log("*> settings change value", value);
          if (value.execute) {
            if (value.execute === "clear") {
              for (const name in this.areas) {
                console.log(" -->2 clearing blocks", name);
                await this.forceClearBlocks(this.areas[name]);
              }
            }
          } else if (value.layout && this.areas[value.layout]) {
            const area = this.areas[value.layout];
            for (const key in value) {
              if (key !== "key") {
                area.settings[key] = value[key];
              }
            }
          } else {
            console.error("settings could not be changed", value);
          }
        });
      } else if (changed === "scene") {
        if (!this.code || party === player.party) {
          this.queue.add(async () => {
            await this.clearBlocks(true);
            this.scene = value;
            this.$emit("update:sceneId", value);
          });
        }
      } else if (changed === "music") {
        this.setMusic(value);
      } else if (changed === "audio") {
        this.setAudio(value);
      } else if (changed === "choice-timer") {
        this.areas.bottom.blocks.push({ type: "choice-timer", timer: value });
      } else if (changed === "choice-timer-ready") {
        this.queue.add(value);
      } else if (changed === "choice-timer-timeout") {
        this.choiceCount = 0;
      } else if (changed === "background") {
        this.queue.add(async () => {
          const layout = value.layout || "bottom";
          if (this.player) {
            this.player.backgrounds[layout] = value;
          }
          // const layout = 'bottom';
          const id = this.areas[layout].bgs.reduce((a, b) => Math.max(a, b.id), 0) + 1;
          const bg = { id, repeat: false, url: null, color: null, zoom: 1, zoomMin: 1, zoomMax: 4 };
          let background = null;

          if (value.content) {
            // using inexact equal (==) for legacy stories
            background = this.story.backgrounds.find((b) => b.id == value.content);

            if (background) {
              // TODO: permalink might have CORS issue on production builds
              // if (background.permalink) {
              //   bg.url = background.permalink;
              // } else
              if (background.blob_id) {
                const media = await Media.load(background.blob_id);
                if (media) {
                  bg.url = await media.getUrl();
                }
              }
              bg.blob_id = background.blob_id;
              bg.repeat = background.repeat;
              bg.zoom = value.zoom || 1;
              bg.zoomMin = value.zoom_min || 1;
              bg.zoomMax = value.zoom_max || 4;
              bg.size = value.size;
            }
          }

          if (value.bgcolor) {
            bg.color = value.bgcolor;
          }

          if (bg.url) {
            await new Promise((r, e) => {
              const img = new Image();
              img.onload = r;
              img.onerror = e;
              img.src = bg.url;
            });
          } else {
            console.warn("Background image is missing", bg, value, background);
          }

          this.areas[layout].bgs.push(bg);

          await this.$nextTick();
          // await timer(100 + 500);
          if (this.areas[layout].bgs.length > 1) {
            this.areas[layout].bgs.shift();
          }
        });
      } else if (changed === "pick") {
        if (inParty) {
          log("choice picked");
          this.choiceCount = 0;

          for (const name in this.areas) {
            for (const block of this.areas[name].blocks.filter((b) => b.type === "choice")) {
              const index = this.areas[name].blocks.findIndex((b) => b.id === block.id);
              if (index > -1 && value.id !== block.id) {
                this.areas[name].blocks.splice(index, 1);
                await timer(100);
                this.areas[name].idle = true;
              }
            }
          }
          this.picks = {};
        }
      } else if (changed === "player-pick") {
        if (
          this.scene === value.scene &&
          (this.last.direction === value.direction || this.last.id === value.direction)
        ) {
          this.picks[value.playerId] = {
            pick: value.id,
            username: this.state[value.playerId].username,
          };
        }
        log("player pick", value, this.last, this.picks);
      } else if (changed === "pause") {
        this.queue.add(async () => {
          await timer(value);
        });
      } else if (changed === "completed" || changed === "finished") {
        this.finished = true;
        this.overlay = null;
      } else if (changed === "event") {
        this.last = value;
        console.warn("event", value);
        const layout = value.layout || "bottom";
        this.areas[layout].idle = false;
        await this.clearBlocks();
        this.$emit("event", value);
      } else if (changed === "block-clear") {
        // better to clear at the start of a new block
        // otherwise the game is cleared before the reader is ready
      } else if (changed === "block-start") {
        this.queue.add(async () => {
          this.exploring = false;
          await this.clearBlocks();
        });
      } else if (changed === "achievement" && value.silent) {
        console.log("silent achievement", value);
      } else if (changed === "contestants") {
        console.log("contestant", value);
        this.readerStore.contestants.push(value);
      } else {
        if (changed === "text" && value.audio && this.narrationVolume > 0) {
          const audioUrl = value.frozenAudio || value.audio;
          Media.load(audioUrl).then(async (media) => {
            if (media) {
              // stop other narrations
              for (const narration of this.currentAudio) {
                if (narration.dataset.narration) {
                  this.disposeAudio(narration);
                }
              }

              const audio = new Audio(await media.getUrl());
              audio.volume = this.narrationVolume;
              audio.dataset.narration = true;
              this.playAndManageAudio(audio);
            } else {
              console.warn(
                "missing narration",
                audioUrl,
                value.frozenAudio,
                value.audio,
                (await Media.list()).map((item) => item.id),
              );
            }
          });
        }

        if (inParty) {
          this.last = value;
          this.queue.add(async () => {
            const layout = value.layout || "bottom";
            this.areas[layout].idle = false;

            await this.$nextTick();

            this.picks = {};
            let skip = false;

            // console.log("changed", changed, value, value.glyphs);

            if (changed === "choice") {
              // background clicks
              if (value.glyphs?.length > 0) {
                skip = true;
                for (const glyph of value.glyphs) {
                  this.glyphs.push({
                    id: `${value.id}-${glyph.id}`,
                    x: glyph.x,
                    y: glyph.y,
                    size: glyph.size,
                    hidden: glyph.hidden,
                    choice: value.id,
                  });
                }
              } else {
                this.choiceCount += 1;
              }
            }
            //  else if (changed === 'choice-timer') {

            // } else {
            //   this.choiceCount = 0;
            // }
            const choice = this.copy(value);
            choice.type = choice.type || changed;
            //(!value.col || value.col === 'center') ? (value.row || 'bottom') : value.col;

            if (layout === "under" || layout === "over") {
              this.areas[layout].blocks.length = 0;
            }
            if (layout !== "top" && layout !== "bottom") {
              this.overlay = layout;
            } else {
              this.overlay = null;
            }

            // stack?
            if (
              ["choice", "input", "option"].indexOf(changed) === -1 &&
              (changed !== "input" || value.label)
            ) {
              log("clearing block stack", changed, value.label);
              await this.clearBlocks();
            }

            this.areas[layout].blocks.push(choice);
            if (!skip) {
              await this.waitForIdle();
            }
          });
          // } else {
          //   console.log('skipping, out of party', changed);
        }
      }
    },
    hurry() {
      for (const name of ["bottom", "top", "left", "right", "over"]) {
        if (this.$refs[name]) {
          this.$refs[name].hurry();
        }
      }
    },
    handleNext() {
      this.showNextButton = false;
      if (this.optionChoices) {
        if (this.isClient) {
          this.$root.playerSendEvent("player-options", this.optionChoices);
        } else {
          this.reader.options(this.optionChoices);
        }
        this.optionChoices = null;
      } else {
        if (this.isClient) {
          this.$root.playerSendEvent("player-next", { next: true });
        } else {
          // this.isIdle = false;
          this.reader.next(this.code ? this.$root.user.id : null);
        }
      }
    },
    handleChoice(c) {
      if (this.isClient) {
        log(
          "sending choice to server",
          this.code,
          this.$root.iamHosting,
          this.isClient,
          this.isServer,
        );
        this.$root.playerSendEvent("player-choice", { choice: c });
      } else {
        this.reader.choice(c, this.code ? this.$root.user.id : null);
      }
    },
    handleInput(e) {
      if (this.isClient) {
        this.$root.playerSendEvent("player-input", { input: e });
      } else {
        this.reader.input(e, this.code ? this.$root.user.id : null);
      }
    },
    handleEvent(name) {
      this.reader.event(name);
    },
    cycleMusicVolume() {
      this.musicVolume = this.cycleVolume(this.musicVolume);
    },
    cycleAudioVolume() {
      this.audioVolume = this.cycleVolume(this.audioVolume);
    },
    cycleNarrationVolume() {
      this.narrationVolume = this.cycleVolume(this.narrationVolume);
    },
    cycleVolume(vol) {
      let retval = vol;
      if (retval === 1) {
        retval = 0.5;
      } else if (retval === 0.5) {
        retval = 0;
      } else {
        retval = 1;
      }
      return retval;
    },
    async fadeMusic(on = true) {
      for (let x = 0; x < 100; x += 1) {
        if (on) {
          this.musicFade = x / 100;
        } else {
          this.musicFade = (100 - x) / 100;
        }
        // eslint-disable-next-line no-await-in-loop
        await timer(5);
      }
    },
    async setMusic(value) {
      if (this.player) {
        this.player.music = value;
      }
      const mediaId = value.blob_id || value.content;
      if (!mediaId) {
        if (this.musicSrc) {
          // fade out
          await this.fadeMusic(false);
          this.musicSrc = null;
        }
      } else if (this.musicNextSrc != mediaId) {
        // fade out current
        const current = this.musicNextSrc;
        this.musicNextSrc = mediaId;

        // many songs are queued, cancel and switch now
        if (this.musicSrc !== current) {
        } else if (this.musicSrc) {
          // fade out
          await this.fadeMusic(false);
        } else {
          // TODO: fade volume
        }

        let clip = await Media.load(mediaId);
        if (clip) {
          clip = mediaId;
        } else {
          clip = this.story.music.find((a) => a.id == mediaId);
          if (clip && this.musicSrc != clip.blob_id) {
            // audio.blob = null;
            this.musicSrc = null;
            clip = clip.blob_id;
          }
        }
        if (clip) {
          const audio = this.$refs.music;
          audio.pause();
          // audio.currentTime = 0;
          this.musicSrc = mediaId;
          this.musicVolumeLocal = value.volume || 1;
          await this.fadeMusic();
        } else {
          this.musicSrc = null;
        }
      }
    },
    async setAudio(value) {
      if (value) {
        const mediaId = value.blob_id || value.content;
        const clip = this.story.audio.find((a) => a.id == mediaId);
        const blob = clip ? clip.blob_id : mediaId;
        const media = await Media.load(blob);
        const audio = new Audio();
        if (media) {
          this.currentAudio.push(audio);
          audio.volume = this.audioVolume;
          audio.src = await media.getUrl();
          this.playAndManageAudio(audio);
        }
      } else {
        this.silenceAudio();
      }
    },
    silenceAudio() {
      for (const audio of this.currentAudio) {
        this.disposeAudio(audio);
      }
      this.currentAudio = [];
    },
    async handleKeyEvent(e) {
      if ((e.which === 32 || e.which == 13) && e.target) {
        if (this.isIdle && !this.isWaiting && !this.hasYourPick && this.needsNextButton) {
          this.handleNext();
        } else {
          this.hurry();
        }
      }
    },
    savePlayer(story) {
      for (const choice of this.player.choices) {
        for (const effect of (choice.effects || []).filter((e) => ":+-".indexOf(e.op) > -1)) {
          // save permanent tags
          const tag = this.story.tags.find((t) => t.id === effect.tag);
          if (tag && tag.permanent) {
            if (!this.player.tags) {
              this.player.tags = {};
            }
            this.player.tags[tag.id] = this.reader.tags[tag.id] || 1;
          }
        }
      }
      if (!this.code) {
        this.player.microcosms = this.reader.microcosms;
        // console.warn("saving player", story.id, JSON.stringify(this.player));
        this.put(`story_${story.id}`, this.player);
      }
    },
    loadPlayer(story) {
      let player = this.fetch(`story_${story.id}`);
      console.warn("loaded player", story.id, player);
      if (!player) {
        player = { tags: {}, choices: [], backgrounds: {}, music: null };
      }
      if (!player.backgrounds) {
        player.backgrounds = {};
      } else {
        Object.values(player.backgrounds).forEach((v) => {
          this.handleReaderEvent({ changed: "background", value: v });
        });
      }
      if (player.music) {
        this.handleReaderEvent({ changed: "music", value: player.music });
      }
      if (!player.tags) {
        player.tags = {};
      }
      return player;
    },
    resetPlayer(story, permanent = false) {
      this.player.choices = [];
      if (permanent) {
        this.player.tags = {};
      }
      this.choiceCount = 0;
      this.glyphs = [];
      this.player.session = null;
      this.player.bookmark = null;
      this.player.music = null;
      this.player.backgrounds = {};
      this.player.microcosms = [];
      this.put(`story_${story.id}`, this.player);
    },
    async handleRestart() {
      if (this.code) {
        this.$root.playerSendEvent("restart", {});
        this.$emit("restart");
      } else {
        this.put(`story_${this.story.id}`, null);
        this.resetPlayer(this.story);
        // this.reader.players.clear();
        this.finished = false;
        this.queue.length = 0;
        for (let name in this.areas) {
          this.areas[name].blocks.length = 0;
          this.areas[name].bgs.length = 0;
        }

        // turn off everything
        this.$refs.music.pause();
        this.musicSrc = null;
        await timer(100);

        this.reader.tags = {};
        this.reader.scene = this.story.scenes.find((s) => s.start);

        // reset the current direction
        this.ready = true;
        this.nextReady = true;
        this.idx = 0;

        // cancel timers
        // this.stopTimer();

        this.reader.reset();
        this.reader.start();
      }
    },
    async handleReset() {
      if (confirm(i18n.global.t("layout.this-will-erase-all-player-data-for-this-story"))) {
        this.player.tags = {};
        await this.handleRestart();
      }
    },
    t(...args) {
      return i18n.global.t(...args);
    },
  },
};
</script>

<style scoped>
.reader-layout {
  font-size: 20px;
  color: var(--story-color);
  /* max-height: 65vh; */
  background: var(--story-bg);
  max-height: 100vh;
  height: -webkit-fill-available;
}
.top-buttons {
  position: absolute;
  top: 0;
  right: 35px;
  z-index: 9;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -4px;
  flex: 0 0 auto;
}

.top-buttons > :deep(a) {
  font-size: 18px;
  padding: 11px 11px;
  width: 40px;
  text-align: center;
  margin-right: 5px;
  color: black !important;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 5px 5px;
}
.reader-content {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  overflow: hidden;
  /* pointer-events: none; */
  touch-action: none;
  transform: translate(0, 0);
}
.reader-content.subdued {
  animation: subdued 250ms ease-in-out forwards;
}
@keyframes subdued {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0, calc(100px + 100%));
  }
}
.explorer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  touch-action: none;
}
.close {
  position: absolute;
  top: 5px;
  right: -30px;
  font-weight: bold;
}
.close.align-left {
  right: calc(100% + 10px);
}
.close.align-top {
  right: 50%;
  top: -50px;
  transform: translate(50%, 0);
}
.close a {
  color: var(--story-color);
  padding: 10px;
  margin: 0;
}
.overlay,
.underlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  padding: 5px;
  margin: 5px;
}
.underlay {
  z-index: 0;
  /* background: var(--story-bg); */
}
.overlay {
  z-index: 40;
}
.overlay .background-image {
  background: var(--story-bg);
}

.top-col {
  flex: 1 0 auto;
}

.left-col,
.right-col {
  padding: 5px 0;
}

.content-cols {
  justify-content: center;
  max-height: 100%;
}
.content-cols > div {
  flex: 1 1 auto;
}
/* #app .area.active {
  border: 2px solid orange;
} */
.area.overlay {
  position: absolute;
  top: 40px;
  bottom: 40px;
  height: min-content;
  max-width: 600px;
  z-index: 10;
  /* border: 2px solid var(--story-color); */
  border-radius: 10px;
  box-shadow: 0 2px 15px var(--story-color);
  padding: 5px 0;
  margin: 10px;
  justify-content: center;
}
#app .area.open {
  display: block;
}

#app .center-col {
  max-width: 1000px;
}
#app .rbottom,
#app .cbottom,
#app .lbottom {
  align-items: flex-end;
  justify-content: flex-end;
}

.reader-footer {
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  padding: 2px;
  height: 50px;
  z-index: 1;
}
.reader-footer .debug-border1 {
  font-size: 10px;
  background: white;
  color: black;
}

#app .sidebar-indicator {
  flex: 0 0 auto;
}
#app .sidebar-indicator a,
#app .sidebar-indicator button {
  font-size: 1em;
  border-radius: 50%;
  color: var(--spark);
  /* opacity: 0.5; */
}

#app .indicators > * {
  margin: 0 10px;
}
#app .indicators button {
  min-width: 35px;
  min-height: 35px;
  overflow: hidden;
}

#app .next a,
#app .next button {
  font-size: 1em;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: var(--spark_text);
  background: var(--spark);
  box-shadow: 0 1px 7px 5px var(--spark_text);
  /* opacity: 0.5; */
}
#app .next a:hover,
#app .next button:hover {
  opacity: 1;
}
#app .next .pick-count {
  color: var(--color1_text);
  background: var(--color1);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app .reader-footer {
  width: 100%;
}
.backdrop,
.sidebar-indicator {
  display: none;
}

@media (max-width: 600px) {
  .sidebar-indicator {
    display: flex;
  }
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    background: var(--story-bg);
    opacity: 0.3;
    z-index: 9;
  }
  .left-col,
  .right-col {
    z-index: 2;
    height: 100vh;
    height: -webkit-fill-available;
    width: 90vw;
    bottom: 0px;
    box-shadow: 0 0 5px var(--spark);
    transition: left 100ms, right 100ms;
  }
  .left-col .background-image,
  .right-col .background-image {
    background: var(--story-bg);
    /* opacity: 0.6; */
  }
  .left-col {
    position: absolute;
    z-index: 10;
    left: -100%;
  }
  .left-col.open {
    left: 0;
  }
  .right-col {
    position: absolute;
    z-index: 10;
    right: -100%;
  }
  .right-col.open {
    right: 0;
  }
}
@media (min-width: 1000px) {
  .reader-footer {
    height: 100px;
    padding: 0 60px;
    padding-bottom: 30px;
    width: 1000px;
    margin: 0 auto;
  }
}
</style>
