<template>
  <AdminList
    v-model="book.backgrounds"
    @update:action="emits('update:action', $event)"
    @update:selected="emits('update:selected', $event)"
    @pick="emits('update:modelValue', $event)"
    :action="props.action"
    :selected="props.selected || props.modelValue"
    :config="schema"
  />
</template>

<script setup lang="ts">
import Media from "@/models/media";
import { useStoryStore } from "@/stores/story";
import { Background } from "@/stores/story_defs";
import AdminList from "@morphosis/base/components/admin/AdminList.vue";
import { VvField } from "@morphosis/base/components/registry";

const book = useStoryStore();
const props = defineProps<{ modelValue?: string; action?: string; selected?: string }>();
const emits = defineEmits(["update:modelValue", "update:action", "update:selected"]);

const schema = {
  searchFields: ["name"],
  listDisplay: [{ label: "Image", source: "blob_id|image:250" }, { source: "media_name" }],
  form: [
    {
      type: "media",
      name: "blob_id",
      label: "File",
      validation: "required",
      attrs: {
        accept: "image",
      },
    },
  ] as VvField[],
  fields: {
    async media_name(record: Background) {
      const media = await Media.load(record.blob_id);
      return (media?.name ?? "").trim() || record.name || "Untitled";
    },
  },
};
</script>

<style scoped>
.header-detail {
  max-height: 200px;
  max-width: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
}
.header-detail .options {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}
.bg {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  max-width: 500px;
  position: relative;
}
.bg img {
  background-color: white;
  flex: 1 1 auto;
  object-fit: cover;
  background-position: center;
  max-height: 100%;
}
.action {
  display: flex;
  grid-column: 1/3;
  height: 100%;
  padding: 5px;
}

.name {
  font-size: 0.9em;
  opacity: 0.7;
  background: white;
  color: black;
  padding: 5px;
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
